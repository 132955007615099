import { combineReducers, Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from '../features/auth/reducers'
import configReducer from '../features/config/reducers'
import resetPasswordReducer from '../features/auth/resetPassword.slice'
import RunApiIssuesSlice from '../features/run-api-defects/runApiDefects.slice'
import webhookReducer from '../features/admin-webhooks/webhooks.slice'

import workspaceReducer from '../features/workspace/workspace.reducer'

import RunOvertimeStatsReducer from './reducers/overtime-stats.reducer'
import { mayhemApi } from './api/mayhemApi'
import { createMayhemApiMiddleware } from './middleware'
import { LOGOUT_SUCCESS } from './fetchMe'

const workspacePersistConfig = {
  key: 'workspace',
  storage,
  version: 1,
  whitelist: ['mru']
}

const appReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  resetPassword: resetPasswordReducer,
  webhooks: webhookReducer,
  workspace: persistReducer(workspacePersistConfig, workspaceReducer),
  experimental: combineReducers({
    runs: combineReducers({
      apiIssues: RunApiIssuesSlice,
      stats: RunOvertimeStatsReducer
    })
  }),
  [mayhemApi.reducerPath]: mayhemApi.reducer
})

export type RootState = ReturnType<typeof appReducer>

export const rootReducer = (state: RootState | undefined, action: Action) => {
  let stateMod = state
  if (action.type === LOGOUT_SUCCESS) {
    stateMod = undefined
  }
  return appReducer(stateMod, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST']
      }
    })
      .prepend(createMayhemApiMiddleware().middleware)
      .concat(mayhemApi.middleware)
})

export default store
export const persistor = persistStore(store)
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export type AppDispatch = typeof store.dispatch
