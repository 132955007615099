import {
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  InputGroup,
  Spinner,
  InputLeftElement,
  ModalBody,
  Kbd,
  ModalFooter,
  ModalHeader,
  Text,
  useStyleConfig,
  ListItemProps,
  ListItem,
  UnorderedList,
  Center
} from '@chakra-ui/react'
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import debounce from 'lodash.debounce'
import { SearchIcon } from '@chakra-ui/icons'
import ErrorIcon from '@material-design-icons/svg/sharp/error.svg?react'

import { isElementOutsideViewport } from '../../util/viewport'

export type SearchProps<Item> = {
  /** The number of milliseconds to debounce input changes by.  Defaults to 300ms. */
  debounceWait?: number
  /** A cancellable promise that takes a search string fetches combo box items. */
  fetchItems: (search: string | undefined, controller: AbortController) => Promise<Item[]>
  /** A callback triggered when the search item selection updates. */
  onSelectedItemChange?: (item: Item | null) => void
  /** Renders results. */
  renderResultRow: (item: Item | null) => JSX.Element
  /** The item selected by the search. This puts the input into controlled mode.  Use with onSelectedItemChange. */
  selectedItem?: Item | null
  /** Function use to render the element that triggers the search modal  */
  renderTrigger: (onOpen: () => void) => JSX.Element
  /** Perform a query with empty string when rendering the component */
  performEmptyQueryOnMount?: boolean
  onOpen: () => void
  onClose: () => void
  isOpen: boolean
}

type FetchState = 'Initial' | 'Fetching' | 'Error' | 'Ready' | 'No Results'

type SearchResultRowProps = ListItemProps

function SearchResultRow(props: SearchResultRowProps): JSX.Element {
  const styles = useStyleConfig('SearchResultRow')

  return (
    <ListItem {...props} sx={styles}>
      {props.children}
    </ListItem>
  )
}

function SearchInner<Item>(props: SearchProps<Item>) {
  const {
    debounceWait = 300,
    fetchItems,
    renderResultRow,
    onSelectedItemChange,
    renderTrigger,
    performEmptyQueryOnMount,
    onOpen,
    onClose,
    isOpen: isOpenModal
  } = props
  const containerRef = useRef<HTMLUListElement | null>(null)

  if (debounceWait < 0) {
    throw Error('debounceWait must be >= 0')
  }

  const [inputItems, setInputItems] = useState<Item[]>([])
  const [, setAbortController] = useState<AbortController | null>(null)
  const [fetchState, setFetchState] = useState<FetchState>('Initial')

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)
  const _onClose = useCallback(() => {
    setInputItems([])
    setSelectedIndex(undefined)
    onClose()
  }, [onClose])

  const handleSelection = useCallback(
    (selectedResult: Item) => {
      // Do something with the selected result (e.g., close the modal and display the selected item)
      if (onSelectedItemChange) {
        onSelectedItemChange(selectedResult)
        _onClose()
      }
    },
    [onSelectedItemChange, _onClose]
  )

  // Handle arrow key navigation and Enter key selection
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // Global shortcut listener for bringing up the modal
      if (inputItems.length === 0) {
        return
      }

      if (event.key === 'ArrowDown') {
        setSelectedIndex((prevIndex) => {
          if (prevIndex === undefined) {
            return 0
          }

          const nextIndex = (prevIndex + 1) % inputItems.length
          if (containerRef.current) {
            const nextItem = containerRef.current.children[nextIndex] as HTMLElement
            if (isElementOutsideViewport(nextItem)) {
              nextItem.scrollIntoView({ behavior: 'smooth', block: 'end' })
            }
          }

          return nextIndex
        })
      } else if (event.key === 'ArrowUp') {
        setSelectedIndex((prevIndex) => {
          if (!prevIndex) {
            return undefined
          }

          const nextIndex = (prevIndex - 1 + inputItems.length) % inputItems.length

          if (containerRef.current) {
            const nextItem = containerRef.current.children[nextIndex] as HTMLElement
            if (isElementOutsideViewport(nextItem)) {
              nextItem.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
          }

          return (prevIndex - 1 + inputItems.length) % inputItems.length
        })
      } else if (event.key === 'Enter') {
        // Handle selection logic here (e.g., pass the selected result to a callback)
        if (selectedIndex !== undefined) {
          handleSelection(inputItems[selectedIndex])
        }
      }
    },
    [handleSelection, inputItems, selectedIndex]
  )

  const debouncedFetch = useRef(
    debounce((inputValue: string) => {
      try {
        const newAbortController = new AbortController()
        setAbortController(newAbortController)
        setFetchState('Fetching')
        fetchItems(inputValue, newAbortController)
          .then((items) => {
            setAbortController(null)
            setInputItems(items)
            if (items.length) {
              setFetchState('Ready')
            } else {
              setFetchState('No Results')
            }
          })
          .catch(() => setFetchState('Error'))
      } catch (err) {
        setFetchState('Error')
      }
    }, debounceWait)
  ).current

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    debouncedFetch(query)
  }

  // Add event listener for keydown when the component mounts
  useEffect(() => {
    // setting the ref to containerRef for the keyDown handler breaks the tests :(
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedIndex, inputItems, handleKeyDown])

  useEffect(() => {
    if (performEmptyQueryOnMount) {
      debouncedFetch('')
    }
  }, [performEmptyQueryOnMount, debouncedFetch])

  const renderFetchStateIcon = () => {
    switch (fetchState) {
      case 'Initial':
      case 'No Results':
      case 'Ready':
        return <SearchIcon />
      case 'Fetching':
        return <Spinner />
      case 'Error':
        return <ErrorIcon />
    }
  }

  const renderNoResultsScreen = () => {
    return (
      <Center>
        <Text> No results found </Text>
      </Center>
    )
  }

  return (
    <>
      {renderTrigger(onOpen)}
      <Modal
        isOpen={isOpenModal}
        onClose={_onClose}
        size="2xl"
        colorScheme="brand"
        scrollBehavior="inside"
        returnFocusOnClose={false}
        variant="search"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <InputGroup>
              <InputLeftElement>{renderFetchStateIcon()}</InputLeftElement>
              <Input onChange={onChangeHandler} placeholder="Search...." />
            </InputGroup>
          </ModalHeader>
          <ModalBody>
            {inputItems.length > 0 && (
              <UnorderedList width="100%" overflowY="auto" marginLeft="0" ref={containerRef}>
                {inputItems.map((item: Item, index) => (
                  <SearchResultRow
                    onMouseEnter={() => {
                      setSelectedIndex(index)
                    }}
                    onMouseLeave={() => {
                      setSelectedIndex(undefined)
                    }}
                    key={index}
                    onClick={() => {
                      handleSelection(item)
                    }}
                    data-highlighted={index === selectedIndex ? true : undefined}
                  >
                    {renderResultRow(item)}
                  </SearchResultRow>
                ))}
              </UnorderedList>
            )}
            {fetchState === 'No Results' && renderNoResultsScreen()}
          </ModalBody>
          <ModalFooter>
            <Text>
              Navigate: <Kbd>↑</Kbd> <Kbd>↓</Kbd>
            </Text>
            <Text>
              Select <Kbd>enter</Kbd>
            </Text>
            <Text>
              Close: <Kbd>esc</Kbd>
            </Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export const SearchModal = React.forwardRef(SearchInner) as <T>(
  props: SearchProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof SearchInner>
