import { Heading } from '@chakra-ui/react'

import { Page } from '../../components/Page'
import { WorkersFullNotice } from '../runs/WorkersFullNotice'

import { DataTab, DataTabs } from '../../components/DataTabs'

import { UserSettingsPersonalInfoPage } from './UserSettingsPersonalInfoPage'
import { UserSettingsAPITokensPage } from './UserSettingsApiTokensPage'
import { UserSettingsWebhooksPage } from './UserSettingsWebhooksPage'
import { UserWorkspacesPage } from './UserWorkspacesPage'

export function UserSettingsPage() {
  const tabData: DataTab[] = [
    {
      label: 'My Details',
      content: <UserSettingsPersonalInfoPage />,
      route: `/-/settings/user`
    },
    {
      label: 'Workspaces',
      content: <UserWorkspacesPage />,
      route: `/-/settings/user/workspaces`
    },
    {
      label: 'Webhooks',
      content: <UserSettingsWebhooksPage />,
      route: `/-/settings/user/webhooks`
    },
    {
      label: 'API Tokens',
      content: <UserSettingsAPITokensPage />,
      route: `/-/settings/user/api-tokens`
    }
  ]

  return (
    <Page>
      <WorkersFullNotice />
      <Heading mb={8}>Account Settings</Heading>

      <DataTabs isLazy variant="line" width="full" tabs={tabData} />
    </Page>
  )
}
