import { Heading, ListItem, OrderedList, Stack } from '@chakra-ui/react'

import { CodeBlock } from '../../components/CodeBlock/CodeBlock'

import { DownloadFileButton } from '../../components/DownloadFileButton'

const StepsToReproduce = ({ owner, projectSlug, targetSlug, sha256, reproCmdline, downloadFileName }: Props): JSX.Element => {
  const fixedCommand = reproCmdline.replace(sha256 || '', downloadFileName || '')

  return (
    <Stack>
      <Heading size="sm">Steps to Reproduce</Heading>
      <OrderedList paddingLeft={6} spacing={6}>
        <ListItem>
          Download the test case file:&nbsp;
          <DownloadFileButton
            aria-label="Download Test Case File"
            href={`/api/v2/owner/${owner}/project/${projectSlug}/target/${targetSlug}/testcase/${sha256 || ''}/file`}
            download={downloadFileName}
          />
        </ListItem>
        <ListItem>Place the file in your working directory.</ListItem>
        <ListItem>In your terminal, run the following command:</ListItem>
      </OrderedList>
      <CodeBlock>{fixedCommand}</CodeBlock>
    </Stack>
  )
}

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  sha256: string | undefined
  reproCmdline: string
  downloadFileName: string | undefined
}

export default StepsToReproduce
