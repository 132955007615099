import { useEffect } from 'react'
import { Card, CardBody, CardHeader, Heading, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip } from '@chakra-ui/react'
import { useLocation } from 'wouter'

import { getProjectsSettingsPageTitle } from '../../titles'
import { getCurrentUserInfo } from '../auth/utils'
import { useAppSelector } from '../../hooks'
import { ProjectVisibilityToggle } from '../project-settings/ProjectVisibilityToggle'
import { useGetAccountQuery } from '../../redux/api/workspace'
import { useGetProjectMemberPermissionQuery } from '../../redux/api/projects'

import { ContributorsTab } from './ContributorsTab'
import { TeamsTab } from './TeamsTab'

type Props = {
  owner: string
  projectSlug: string
}

export function AccessControlPage({ owner, projectSlug }: Props) {
  const [location, setLocation] = useLocation()

  const { userSlug: currentUserSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { data: workspace, isLoading: isWorkspaceLoading } = useGetAccountQuery({ owner })
  const { data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner,
    projectSlug,
    userSlug: currentUserSlug
  })

  const canManageTeams = workspace?.is_org

  useEffect(() => {
    document.title = getProjectsSettingsPageTitle(owner, projectSlug, 'Access Control')
  }, [location, owner, projectSlug, setLocation])

  const isAtLeastWritePermission = isMayhemAdmin || (projectPermission && projectPermission.permission !== 'READ')

  return (
    <Stack spacing={8}>
      {isAtLeastWritePermission && (
        <Card mb={8}>
          <CardHeader>
            <Heading variant="cardHeading">Project Visibility</Heading>
          </CardHeader>
          <CardBody p={4}>
            <ProjectVisibilityToggle workspace={owner} projectSlug={projectSlug} isAdmin={isAtLeastWritePermission} />
          </CardBody>
        </Card>
      )}
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Contributors</Heading>
        </CardHeader>
        <CardBody padding={8}>
          <Tabs variant="line">
            <TabList>
              <Tab>Users</Tab>
              <Tab isDisabled={isWorkspaceLoading || !canManageTeams}>
                <Tooltip
                  isDisabled={canManageTeams}
                  label={`The project "${projectSlug}" is a part of the ${owner} personal account. Teams can only be created and added to projects belonging to organizations.`}
                >
                  Teams
                </Tooltip>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ContributorsTab workspace={owner} projectSlug={projectSlug} />
              </TabPanel>
              <TabPanel>
                <TeamsTab workspace={owner} projectSlug={projectSlug} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </Stack>
  )
}
