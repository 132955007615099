import { ComponentStyleConfig } from '@chakra-ui/react'

export const Table: ComponentStyleConfig = {
  variants: {
    simple: {
      th: {
        borderColor: 'inherit'
      },
      td: {
        borderColor: 'inherit'
      }
    }
  }
}
