import React, { useCallback } from 'react'

import { useLocation } from 'wouter'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'

import debounce from 'lodash/debounce'

import { useQuery } from '../hooks'
import { stringifyUrl } from '../util/location'
import { usePathname } from '../hooks.wouter'

interface Props {
  placeholder?: string
}

export function SearchBar({ placeholder }: Props) {
  const [_, setLocation] = useLocation()
  const pathname = usePathname()

  const query = useQuery()
  const currentQueryText = query.get('q')

  const searchRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    query.delete('page')
    const queryText = event.target.value
    if (queryText) {
      query.set('q', encodeURIComponent(event.target.value))
    } else {
      query.delete('q')
    }
    setLocation(stringifyUrl(pathname, Object.fromEntries(query)), { replace: true })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(searchRequest, 500), [pathname])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()

    debouncedSearch(event)
  }

  return (
    <InputGroup minWidth="9" maxWidth={['100%', '100%', '80']} w={['100%', '100%', '30%']} size="md">
      <InputLeftAddon>
        <SearchIcon />
      </InputLeftAddon>
      <Input paddingY="2" paddingX="4" placeholder={placeholder} onChange={onChange} defaultValue={currentQueryText || ''} />
    </InputGroup>
  )
}
