import React, { MutableRefObject, createContext } from 'react'

import { Link, useLocation } from 'wouter'
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalFooter,
  useToast,
  Text,
  HStack,
  Box,
  Stack
} from '@chakra-ui/react'
import BugReportIcon from '@material-design-icons/svg/sharp/bug_report.svg?react'
import CloseIcon from '@material-design-icons/svg/sharp/close.svg?react'
import DeleteIcon from '@material-design-icons/svg/sharp/delete.svg?react'
import DownloadIcon from '@material-design-icons/svg/sharp/download.svg?react'
import EditIcon from '@material-design-icons/svg/sharp/edit.svg?react'
import InsertDriveFileIcon from '@material-design-icons/svg/sharp/insert_drive_file.svg?react'
import ListAltIcon from '@material-design-icons/svg/sharp/list_alt.svg?react'
import MoreHorizontalIcon from '@material-design-icons/svg/sharp/more_horiz.svg?react'
import PlayArrowIcon from '@material-design-icons/svg/sharp/play_arrow.svg?react'
import RepeatIcon from '@material-design-icons/svg/sharp/repeat.svg?react'
import StopIcon from '@material-design-icons/svg/sharp/stop.svg?react'

import { excludeNullUndefinedFields } from '../../util/typeUtils'

import MessageModal from '../../components/MessageModal'
import { mayhemLoader } from '../../utils'
import { stringifyUrl } from '../../util/location'
import { cleanInteger } from '../../util/numbers'

import { useAppSelector } from '../../hooks'
import { useDeleteRunMutation, useGetRunQuery, usePostTargetRunMutation, usePutRunPhaseMutation } from '../../redux/api/runs'
import { getFormValuesFromYaml } from '../create/mayhemfileEditor/utils'
import { goToCodeRunConfigPage } from '../run-code-create/utils'

import { useGetProjectMemberPermissionQuery } from '../../redux/api/projects'
import { getCurrentUserInfo } from '../auth/utils'

import RunDeleteModal from './RunDeleteModal'
import { MayhemfilePopup } from './MayhemfilePopup'

interface Props {
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  dropdownOnly?: boolean
}

export const RunDetailsModalContext = createContext<MutableRefObject<HTMLElement | null> | null>(null)

export function RunOptionsButton({ dropdownOnly, ...params }: Props) {
  const { owner, projectSlug, targetSlug, runNumber } = params
  const toast = useToast()

  const [deleteRunMutation] = useDeleteRunMutation()
  const [rerunTrigger] = usePostTargetRunMutation()

  const [location, setLocation] = useLocation()

  const { isOpen: isViewMayhemfileModalOpen, onOpen: onViewMayhemfileModalOpen, onClose: onViewMayhemfileModalClose } = useDisclosure()
  const { isOpen: isStopRunModalOpen, onOpen: onStopRunModalOpen, onClose: onStopRunModalClose } = useDisclosure()

  const {
    isOpen: isRunDeleteConfirmationModalOpen,
    onOpen: onRunDeleteConfirmationModalOpen,
    onClose: onRunDeleteConfirmationModalClose
  } = useDisclosure()
  const { isOpen: isEditOrRerunModalOpen, onOpen: onEditOrRerunModalOpen, onClose: onEditOrRerunModalClose } = useDisclosure()
  const { isOpen: isMessageModalOpen, onOpen: onMessageModalOpen, onClose: onMessageModalClose } = useDisclosure()

  const { isUninitialized, isLoading: isRunLoading, data: run, refetch } = useGetRunQuery(params)
  const { userSlug: currentUserSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner,
    projectSlug,
    userSlug: currentUserSlug
  })

  const isAdmin = isMayhemAdmin || (projectPermission && projectPermission.permission === 'ADMIN')
  const [updateRunPhase] = usePutRunPhaseMutation()

  if (isRunLoading) {
    return <Skeleton />
  }

  // it would be nice to have the target type on the run to avoid another fetch
  // or determining target type by the presence of api_run
  const isCodeRun = !run?.api_run
  const isApiRun = !isCodeRun

  const rootSha = run?.run_files?.find((x) => x.path === 'root.tgz')?.sha256

  const handleStopRunClick = () => {
    Promise.all(
      run?.phases
        ?.filter((task) => !task.ended_at)
        ?.map((task) =>
          updateRunPhase({
            ...params,
            phaseName: task.phase_name as string,
            runPhase: {
              stopped: true
            }
          })
        ) || []
    ).then(() => {
      if (!isUninitialized) {
        refetch()
      }
    })
    onStopRunModalClose()
  }

  const handleRunCoverageAnalysisClick = () => {
    // Stop all the phases except for coverage analysis
    Promise.all(
      run?.phases
        ?.filter((task) => !task.ended_at)
        ?.filter((task) => task.phase_name !== 'coverage_analysis')
        ?.map((task) =>
          updateRunPhase({
            ...params,
            phaseName: task.phase_name as string,
            runPhase: {
              stopped: true
            }
          })
        ) || []
    ).then(() => {
      if (!isUninitialized) {
        refetch()
      }
    })
    onStopRunModalClose()
  }

  const handleDeleteRunClick = (): void => {
    onRunDeleteConfirmationModalOpen()
  }

  const handleRunAgainClick = (): void => {
    const parsedMayhemfile = getFormValuesFromYaml(run?.mayhemfile_flat)
    const [mayhemfileVersionMajor, mayhemfileVersionMinor] = (parsedMayhemfile?.version || '').split('.')
    // only 2.0+ or no version defined are eligible to be used by the Mayhemfile Editor
    const isEditableMayhemfile =
      parsedMayhemfile?.version === undefined || (cleanInteger(mayhemfileVersionMajor, 0) >= 2 && cleanInteger(mayhemfileVersionMinor, 0) >= 0)

    if (isEditableMayhemfile) {
      onEditOrRerunModalOpen()
    } else {
      dispatchRerunRunHandler()
    }
  }

  const goToMayhemfileEditorHandler = () => {
    if (run?.mayhemfile_flat) {
      setLocation(goToCodeRunConfigPage(owner, run.mayhemfile_flat, rootSha))
    }
  }

  const dispatchRerunRunHandler = async () => {
    if (!run) {
      return
    }

    const runPost = excludeNullUndefinedFields({
      phases: run.phases?.map((p) => p.phase_name || ''),
      duration: run.duration,
      run_files: run?.run_files,
      env_vars: run.env_vars,
      overrides: run.overrides,
      docker_registry_id: run.docker_registry_id,
      docker_pullable: run.docker_pullable
    })

    try {
      const newRun = await rerunTrigger({
        owner,
        projectSlug,
        targetSlug,
        runPost
      }).unwrap()
      setLocation(`/${owner}/${projectSlug}/${targetSlug}/${newRun.run_number}`)
    } catch (e) {
      console.error(e)
      toast({
        title: 'Rerun failed.',
        description: 'There was an error replaying the run.',
        status: 'error',
        duration: 8000,
        isClosable: true
      })
    }
  }
  const dispatchDeleteRun = async () => {
    mayhemLoader.open()
    try {
      await deleteRunMutation({ owner, projectSlug, targetSlug, runNumber }).unwrap()
      mayhemLoader.close()
      const isOnRunPage = location && location.includes(`/${owner}/${projectSlug}/${targetSlug}/${runNumber}`)
      const historyIsShort = history.length <= 2
      toast({
        title: 'Run deleted succesfully.',
        status: 'success',
        duration: 8000,
        isClosable: true
      })
      if (isOnRunPage) {
        if (historyIsShort) {
          setLocation('/-/runs')
        } else {
          history.back()
        }
      }
    } catch (e) {
      mayhemLoader.close()
      console.error(e)
    }
  }

  const renderDownloadTestsuiteItem = (): JSX.Element => {
    const filterParams = { run_number: runNumber, optimized_only: true }

    if (isAdmin) {
      return (
        <MenuItem
          data-selenium-id="testsuite"
          as="a"
          download="testsuite.tar"
          href={stringifyUrl(`/api/v2/owner/${owner}/project/${projectSlug}/target/${targetSlug}/testsuite.tar`, filterParams)}
          target="_blank"
          rel="noopener noreferrer"
          icon={<DownloadIcon />}
        >
          Download Test Suite
        </MenuItem>
      )
    }

    return (
      <React.Fragment>
        <MenuItem data-selenium-id="testsuite" onClick={onMessageModalOpen} icon={<DownloadIcon />}>
          Download Test Suite
        </MenuItem>
        <MessageModal
          message="Insufficient permissions to download examples. You must be a member of the organization. If this is an error please contact the owner."
          title="Member Feature"
          negative
          open={isMessageModalOpen}
          onClose={onMessageModalClose}
        />
      </React.Fragment>
    )
  }

  const renderButton = (): JSX.Element => {
    const isRunning = run && !run.pending && !run.ended

    const showDeleteRunButton = !!run?.pending
    // TODO(kostas): make sure button is disabled after it's pressed
    const showStopRunButton = isRunning
    const showRunOptionsDropdownButton = !run?.pending && run?.ended
    const showEndedOptions = !!run?.ended

    const urlFromFileSha = (sha?: string, filename?: string): string => {
      return !sha ? '' : `/api/v2/owner/${owner}/project/${projectSlug}/file/${sha}/${filename}`
    }

    if (showRunOptionsDropdownButton || dropdownOnly) {
      return (
        <Menu isLazy={true}>
          <MenuButton as={IconButton} icon={<MoreHorizontalIcon />} variant="outline" aria-label="Run Options" title="Run Options" />
          <MenuList zIndex={2}>
            {isCodeRun && (
              <MenuItem isDisabled={!run?.mayhemfile_flat} onClick={onViewMayhemfileModalOpen} icon={<InsertDriveFileIcon />}>
                Mayhemfile
              </MenuItem>
            )}
            {isCodeRun && renderDownloadTestsuiteItem()}

            <MenuItem key="events" as={Link} to={`/${owner}/${projectSlug}/${targetSlug}/${runNumber}/log#run-events`} icon={<ListAltIcon />}>
              Event Log
            </MenuItem>

            {isApiRun && !!run?.api_run?.sarif_sha && (
              <MenuItem
                key="sarif"
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                download="sarif.xml"
                href={urlFromFileSha(run?.api_run?.sarif_sha, 'sarif.xml')}
                icon={<DownloadIcon />}
              >
                SARIF Report
              </MenuItem>
            )}

            {isApiRun && !!run?.api_run?.junit_sha && (
              <MenuItem
                key="junit"
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                download="junit.xml"
                href={urlFromFileSha(run?.api_run?.junit_sha, 'JUnit.xml')}
                icon={<DownloadIcon />}
              >
                JUnit Report
              </MenuItem>
            )}

            {isApiRun && !!run?.api_run?.html_report_sha && (
              <MenuItem
                key="html_report"
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                download="report.html"
                href={urlFromFileSha(run?.api_run?.html_report_sha, 'report.html')}
                icon={<DownloadIcon />}
              >
                HTML Run Report
              </MenuItem>
            )}

            {isApiRun && !!run?.api_run?.reports_zip_sha && (
              <MenuItem
                key="reports_zip"
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                download="reports.zip"
                href={urlFromFileSha(run?.api_run?.reports_zip_sha, 'reports.zip')}
                icon={<DownloadIcon />}
              >
                All Reports
              </MenuItem>
            )}

            {isCodeRun && (
              <MenuItem key="defects" as={Link} to={`/${owner}/${projectSlug}/${targetSlug}/${runNumber}/defects`} icon={<BugReportIcon />}>
                Defects Report
              </MenuItem>
            )}
            <MenuDivider />
            {showEndedOptions && isCodeRun && (
              <MenuItem key="rerun" onClick={handleRunAgainClick} icon={<RepeatIcon />}>
                Run Again
              </MenuItem>
            )}
            <MenuItem key="deleterun" isDisabled={run?.is_deleted} onClick={onRunDeleteConfirmationModalOpen} icon={<DeleteIcon />}>
              Delete Run
            </MenuItem>
          </MenuList>
        </Menu>
      )
    }

    if (showDeleteRunButton) {
      return (
        <IconButton
          data-selenium-name="runOptions"
          icon={<CloseIcon />}
          variant="outline"
          aria-label="Delete Run"
          title="Delete Run"
          onClick={handleDeleteRunClick}
        />
      )
    }

    if (showStopRunButton) {
      // In case the run has coverage analysis enabled we show a confirmation modal
      if ((run.phases || []).some((phase) => phase.phase_name === 'coverage_analysis')) {
        return (
          <>
            <IconButton
              data-selenium-name="runOptions"
              icon={<StopIcon />}
              variant="outline"
              colorScheme="red"
              aria-label="Stop Run"
              title="Stop Run"
              onClick={onStopRunModalOpen}
            />
            <Modal isOpen={isStopRunModalOpen} onClose={onStopRunModalClose} size="lg">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Run Coverage?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>
                    You specified the Coverage Analysis task for this run, which requires Behavior Testing to complete. Do you want to fully stop the
                    run, or let Coverage Analysis run first before stopping?
                  </Text>
                </ModalBody>

                <ModalFooter>
                  <HStack gap={2}>
                    <Button variant="outline" colorScheme="black" onClick={handleRunCoverageAnalysisClick}>
                      Run Coverage Analysis
                    </Button>
                    <Button variant="outline" colorScheme="black" onClick={handleStopRunClick} aria-label="Confirm Stop Run" title="Confirm Stop Run">
                      Stop Run
                    </Button>
                  </HStack>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )
      }
      return (
        <IconButton
          data-selenium-name="runOptions"
          icon={<StopIcon />}
          variant="outline"
          colorScheme="red"
          aria-label="Stop Run"
          title="Stop Run"
          onClick={handleStopRunClick}
        />
      )
    }

    return <React.Fragment />
  }

  return (
    <Box role="presentation" onClick={(e) => e.stopPropagation()} onKeyPress={(e) => e.stopPropagation()}>
      {renderButton()}
      <RunDeleteModal
        owner={owner}
        projectSlug={projectSlug}
        targetSlug={targetSlug}
        runNumber={runNumber}
        deleteHandler={dispatchDeleteRun}
        closeHandler={onRunDeleteConfirmationModalClose}
        open={isRunDeleteConfirmationModalOpen}
      />
      {isViewMayhemfileModalOpen && (
        <MayhemfilePopup
          isOpen={isViewMayhemfileModalOpen}
          owner={owner}
          projectSlug={projectSlug}
          targetSlug={targetSlug}
          runNumber={runNumber}
          onClose={onViewMayhemfileModalClose}
        />
      )}
      {isEditOrRerunModalOpen && (
        <Modal isOpen={true} onClose={onEditOrRerunModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Mayhemfile?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <Text>Would you like to edit the Mayhemfile before starting a new run?</Text>
                <Text as="i">
                  Note: When performing future runs through the CLI, mayhem sync will need to be run first in order to fetch the latest Mayhemfile.
                </Text>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outline"
                leftIcon={<EditIcon />}
                onClick={() => {
                  onEditOrRerunModalClose()
                  goToMayhemfileEditorHandler()
                }}
              >
                Edit Mayhemfile
              </Button>
              <Button
                leftIcon={<PlayArrowIcon />}
                onClick={() => {
                  onEditOrRerunModalClose()
                  dispatchRerunRunHandler()
                }}
                marginLeft={4}
              >
                Run without Editing
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  )
}
