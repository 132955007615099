import AddIcon from '@material-design-icons/svg/sharp/add.svg?react'
import ListIcon from '@material-design-icons/svg/sharp/list.svg?react'
import SwitchIcon from '@material-design-icons/svg/sharp/unfold_more.svg?react'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuButtonProps,
  Avatar,
  Spinner,
  Icon,
  MenuGroup,
  MenuDivider,
  Portal,
  Button
} from '@chakra-ui/react'
import { Box, Grid, GridItem, HStack } from '@chakra-ui/layout'
import { Link } from 'wouter'

import { ReactNode } from 'react'

import { useGetAccountQuery, useGetOrgsByUserQuery, useWhoamiQuery } from '../../redux/api/workspace'
import { link } from '../workspace/utils'

export const WorkspaceDropdown = ({ workspace, menuButton = {}, icon }: WorkspaceDropdownProps) => {
  const { isLoading: isLoadingProfile, data: profile } = useWhoamiQuery()
  const { isLoading: isLoadingWorkspaces, data: orgResponse } = useGetOrgsByUserQuery(
    profile
      ? {
          userSlug: profile.slug as string
        }
      : skipToken
  )
  const { isLoading: isLoadingActiveWorkspace, data: activeWorkspace } = useGetAccountQuery(workspace ? { owner: workspace } : skipToken)

  // TODO(kostas): Display workspace avatar when the API is updated
  // Workspaces are a tuple of [display name, slug, avatar icon]
  const workspaces: Array<[string, string, JSX.Element]> = []

  if (orgResponse !== undefined && orgResponse.orgs !== undefined) {
    orgResponse.orgs.map((organization) =>
      workspaces.push([
        organization.orgname,
        organization.slug || organization.orgname,
        <Avatar key={organization.orgname} name={organization.orgname} size="sm" bg="gray" color="white" />
      ])
    )
  }
  if (profile !== undefined) {
    workspaces.push([
      profile.username,
      profile.slug || profile.username,
      profile.avatar ? (
        <Avatar key={profile.username} name={profile.username} src={profile.avatar} size="sm" bg="gray" color="white" />
      ) : (
        <Avatar key={profile.username} name={profile.username} size="sm" bg="gray" color="white" />
      )
    ])
  }

  // TODO(kostas): Handle query failures

  return (
    <Menu isLazy>
      <MenuButton {...menuButton} as={Button} disabled={isLoadingProfile || isLoadingActiveWorkspace} variant="sidenav-link" padding={0} height={16}>
        <Grid
          templateColumns="auto 1fr"
          templateAreas={`
        "avatar dropdown"
      `}
          columnGap={4}
          width="full"
        >
          <GridItem gridArea="avatar" display="flex" alignItems="center">
            {icon}
          </GridItem>
          <GridItem gridArea="dropdown" overflowX="auto">
            <Box display="flex" height="full">
              <HStack overflowX="hidden" justifyContent="space-between" width="full">
                <Text
                  flex="1"
                  overflowX="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  textAlign="left"
                  fontSize="md"
                  fontWeight="semibold"
                  margin="0"
                >
                  {activeWorkspace?.orgname || 'Settings'}
                </Text>
                {!isLoadingWorkspaces ? <Icon as={SwitchIcon} boxSize={6} /> : <Spinner boxSize={4} />}
              </HStack>
            </Box>
          </GridItem>
        </Grid>
      </MenuButton>
      <Portal>
        <MenuList>
          <MenuGroup title="Choose Workspace">
            {workspaces.map(([displayName, slug, icon]) => (
              <MenuItem key={slug} as={Link} to={link(slug)}>
                <HStack spacing={4}>
                  {icon}
                  <Text>{displayName}</Text>
                </HStack>
              </MenuItem>
            ))}
          </MenuGroup>
          <MenuDivider />
          <MenuItem key="workspace-list" as={Link} to="/-/settings/user/workspaces" icon={<Icon as={ListIcon} boxSize={6} />}>
            My Workspaces
          </MenuItem>
          <MenuItem key="workspace-create" as={Link} to="/-/settings/create-workspace" icon={<Icon as={AddIcon} boxSize={6} />}>
            Create Workspace
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}

export interface WorkspaceDropdownProps {
  workspace?: string
  menuButton?: MenuButtonProps
  icon: ReactNode
}
