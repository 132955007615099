import { useEffect } from 'react'
import { Card, CardHeader, Heading, Button, CardBody, useDisclosure, Text, Box } from '@chakra-ui/react'

import { CreateWebhookModal } from '../admin-webhooks/CreateWebhookModal'
import { WebhookList } from '../admin-webhooks/WebhookList'
import { getOwnerSettingsPageTitle } from '../../titles'
import { useAppSelector } from '../../hooks'

import { getCurrentUserInfo } from '../auth/utils'

export function UserSettingsWebhooksPage() {
  const { userSlug: currentUserSlug } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { isOpen, onClose, onOpen } = useDisclosure()

  useEffect(() => {
    document.title = getOwnerSettingsPageTitle(currentUserSlug, 'Webhooks')
  }, [currentUserSlug])

  return (
    <Card>
      <CardHeader>
        <Box m="3">
          <Heading variant="cardHeading">Webhooks</Heading>
          <Text size="sm">Create and manage webhooks that automatically send messages from Mayhem to other apps.</Text>
        </Box>
        <Button onClick={onOpen}>Create New Webhook</Button>
      </CardHeader>
      <CardBody>
        <WebhookList workspace={currentUserSlug} />
        <CreateWebhookModal isOpen={isOpen} onClose={onClose} subscription={{ owner: currentUserSlug }} />
      </CardBody>
    </Card>
  )
}
