import { ApiUserType } from '../admin/types'
import { RECEIVE_ME, LOGIN_FAILURE, LOGOUT_SUCCESS, RECEIVE_KEYCLOAK } from '../../redux/fetchMe'

import { AuthAction } from './actions'

export const userMapper = <T extends { slug?: string }>(_user: T): T & { userSlug?: string } => ({
  ..._user,
  userSlug: _user.slug
})

export type AuthState = {
  isAuthenticated: boolean
  isFetching: boolean | null
  logout: boolean
  forgotPasswordSuccess: boolean | undefined
  isFetchingForgotPassword: boolean
  isFetchingResetPassword: boolean
  user: ApiUserType | Record<string, never>
  keycloakUserId: string | undefined
}

export default function authReducer(
  state: AuthState = {
    isAuthenticated: false,
    isFetching: true,
    logout: false,
    forgotPasswordSuccess: undefined,
    isFetchingForgotPassword: false,
    isFetchingResetPassword: false,
    user: {},
    keycloakUserId: undefined
  },
  action: AuthAction
): AuthState {
  switch (action.type) {
    case RECEIVE_ME:
      return {
        ...state,
        user: userMapper({ ...action.user }),
        isAuthenticated: true,
        isFetching: false,
        logout: false
      }

    case RECEIVE_KEYCLOAK:
      return {
        ...state,
        keycloakUserId: action.keycloakUserId
      }

    case LOGIN_FAILURE:
      // Do not erase forgot-password flow state.
      return {
        forgotPasswordSuccess: state.forgotPasswordSuccess,
        isFetchingForgotPassword: state.isFetchingForgotPassword,
        isFetchingResetPassword: state.isFetchingResetPassword,
        isFetching: false,
        isAuthenticated: false,
        logout: false,
        user: {},
        keycloakUserId: undefined
      }

    case LOGOUT_SUCCESS:
      return { ...state, isFetching: false, isAuthenticated: false, logout: true }

    default:
      return state
  }
}
