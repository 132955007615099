import { ComponentStyleConfig } from '@chakra-ui/react'
import { StyleFunctionProps, transparentize } from '@chakra-ui/theme-tools'

import { theme } from '../theme'

export const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'normal'
  },
  variants: {
    'primary-on-accent': () => ({
      bg: 'brand.900',
      color: 'brand.600',
      _hover: { bg: 'brand.100' },
      _active: { bg: 'brand.100' }
    }),
    'ghost-on-accent': (props: StyleFunctionProps) => ({
      color: 'brand.100',
      _hover: {
        bg: transparentize('brand.500', 0.67)(props.theme)
      },
      _activeLink: {
        color: 'white',
        bg: 'bg-accent-subtle'
      }
    }),
    'link-on-accent': () => {
      return {
        padding: 0,
        height: 'auto',
        lineHeight: 'normal',
        verticalAlign: 'baseline',
        color: 'brand.200',
        _hover: {
          color: 'white'
        },
        _active: {
          color: 'white'
        }
      }
    },
    secondary: (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants.outline(props),
      borderColor: props.colorMode === 'dark' ? 'brand.50' : 'brand.500',
      color: props.colorMode === 'dark' ? 'brand.50' : 'brand.500',
      _hover: {
        bg: props.colorMode === 'dark' ? 'brand.200' : 'brand.50'
      }
    }),
    // for buttons on colored backgrounds (e.g. warning/error card headers)
    'outline-inverse': (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants.outline(props),
      borderColor: 'body',
      color: 'body',
      _active: {
        background: props.colorMode === 'dark' ? 'blackAlpha.400' : 'whiteAlpha.400',
        color: props.colorMode === 'dark' ? 'body' : 'whiteAlpha.700'
      },
      _hover: {
        background: props.colorMode === 'dark' ? 'blackAlpha.600' : 'whiteAlpha.600',
        color: props.colorMode === 'dark' ? 'body' : 'whiteAlpha.900'
      }
    }),
    'sidenav-link': (props: StyleFunctionProps) => ({
      ...theme.components.Button.variants.ghost(props),
      color: props.colorMode === 'dark' ? 'gray.200' : 'gray.700',
      // when the sidenav link is being clicked
      _active: {
        background: 'activeBg',
        color: props.colorMode === 'dark' ? 'gray.200' : 'gray.700'
      },
      // when the sidenav link is the currently displayed page
      _activeLink: {
        background: 'selectedBg',
        color: props.colorMode === 'dark' ? 'gray.200' : 'gray.700'
      },
      _hover: {
        background: 'hoverBg',
        color: props.colorMode === 'dark' ? 'gray.50' : 'gray.900'
      }
    })
  },
  defaultProps: {
    variant: 'solid'
  }
}
