import { UnknownAction, Dispatch } from 'redux'

import { post, get } from '../../api'
import { AppThunk } from '../../redux/store'
import { clearMruWorkspace } from '../workspace-dashboard/mru.slice'
import { checkAuthentication, fetchApiInfo, fetchMayhemInfo } from '../config/actions'

import {
  fetchMe,
  LoginFailureAction,
  LogoutSuccessAction,
  ReceiveKeycloakAction,
  receiveLogin,
  ReceiveLoginAction,
  receiveLogout,
  ReceiveMeAction
} from '../../redux/fetchMe'

import UserService from './UserService'

export type AuthAction = ReceiveMeAction | ReceiveKeycloakAction | LoginFailureAction | LogoutSuccessAction | ReceiveLoginAction

export function login(email: string, password: string): AppThunk<Promise<void>> {
  return async (dispatch: Dispatch) => {
    await post('/api/v2/user/login', { email, password })
    dispatch(receiveLogin())
    dispatch(fetchMe() as unknown as UnknownAction)
  }
}

export function signup(
  email: string,
  password: string,
  username: string,
  firstName: string,
  lastName: string,
  inviteToken: string | null | undefined
): AppThunk<Promise<boolean>> {
  return async (dispatch) => {
    const user = await post<{ verified: boolean }>('/api/v2/user', {
      email,
      password,
      username,
      first_name: firstName,
      last_name: lastName,
      invite_token: inviteToken || undefined
    })
    if (user.verified) {
      dispatch(receiveLogin()) // If server has no email sending capabilities, it will simply return verified
      await dispatch(fetchMe())
    }
    return user.verified
  }
}

export function logout(): AppThunk<Promise<unknown>> {
  return async (dispatch) => {
    // Clear the cookie
    await get('/api/v2/user/logout')

    await dispatch(clearMruWorkspace())

    if (UserService.isLoggedInToKeycloak()) {
      await UserService.doLogoutFromKeycloak()
    } else {
      await dispatch(receiveLogout())

      await dispatch(fetchApiInfo())
      await dispatch(fetchMayhemInfo())

      await dispatch(checkAuthentication())
    }
  }
}
