export type StatusParam = 'all' | 'running' | 'pending' | 'completed' | 'failed' | 'stopped'

export const statusOptions: Array<{ key: string; text: string; value: StatusParam }> = [
  {
    key: 'All Runs',
    text: 'All Runs',
    value: 'all'
  },
  {
    key: 'Active Runs',
    text: 'Active Runs',
    value: 'running'
  },
  {
    key: 'Pending Runs',
    text: 'Pending Runs',
    value: 'pending'
  },
  {
    key: 'Completed Runs',
    text: 'Completed Runs',
    value: 'completed'
  },
  {
    key: 'Failed Runs',
    text: 'Failed Runs',
    value: 'failed'
  },
  {
    key: 'Stopped Runs',
    text: 'Stopped Runs',
    value: 'stopped'
  }
]
