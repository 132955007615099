import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, HStack, Hide } from '@chakra-ui/react'
import { Link as WouterLink, useLocation } from 'wouter'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

import { TruncatedTextWithTooltip } from '../../components/TruncatedTextWithTooltip'
import { useGetRunQuery } from '../../redux/api/runs'

export interface RunBreadcrumbProps {
  workspace: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  runNumberAsLink?: boolean
}

export const RunBreadcrumb = ({ workspace, projectSlug, targetSlug, runNumber, runNumberAsLink }: RunBreadcrumbProps) => {
  const { data: run } = useGetRunQuery({
    owner: workspace,
    projectSlug: projectSlug,
    targetSlug: targetSlug,
    runNumber
  })

  const [_location, setLocation] = useLocation()

  const previous_run_number = run?.previous_run?.run_number
  const next_run_number = run?.next_run_number

  const navigateToRun = (toRunNumber: number) => {
    setLocation(`/${workspace}/${projectSlug}/${targetSlug}/${toRunNumber}`)
  }

  return (
    <HStack justifyContent="space-between">
      <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
        <BreadcrumbItem>
          <WouterLink to={`/${workspace}/${projectSlug}`}>
            <BreadcrumbLink>
              <TruncatedTextWithTooltip text={run?.project_name || projectSlug} />
            </BreadcrumbLink>
          </WouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <WouterLink to={`/${workspace}/${projectSlug}/${targetSlug}`}>
            <BreadcrumbLink>
              <TruncatedTextWithTooltip text={run?.target_name || targetSlug} />
            </BreadcrumbLink>
          </WouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage={!runNumberAsLink}>
          {runNumberAsLink ? (
            <BreadcrumbLink as={WouterLink} to={`/${workspace}/${projectSlug}/${targetSlug}/${runNumber}`}>
              Run {runNumber}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink>Run {runNumber}</BreadcrumbLink>
          )}
        </BreadcrumbItem>
      </Breadcrumb>
      <HStack>
        {previous_run_number && (
          <Button
            variant="outline"
            leftIcon={<ChevronLeftIcon />}
            iconSpacing={{ base: 0, sm: 2 }}
            onClick={() => navigateToRun(previous_run_number)}
          >
            <Hide below="sm">Previous Run</Hide>
          </Button>
        )}
        {next_run_number && (
          <Button variant="outline" rightIcon={<ChevronRightIcon />} iconSpacing={{ base: 0, sm: 2 }} onClick={() => navigateToRun(next_run_number)}>
            <Hide below="sm">Next Run</Hide>
          </Button>
        )}
      </HStack>
    </HStack>
  )
}
