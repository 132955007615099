import { useEffect } from 'react'

import { Stack } from '@chakra-ui/react'

import { getProjectDefectsPageTitle } from '../../titles'

import { useQuery } from '../../hooks'
import ProjectDefectsHeader from '../defect/ProjectDefectsHeader'
import { ProjectDefectsList } from '../defect/ProjectDefectsList'

import { useGetDefectStatsByProjectQuery } from '../../redux/api/defects'

type Props = {
  owner: string
  projectSlug: string
}

export function ProjectDefectsPage(props: Props) {
  const { owner, projectSlug } = props
  const query = useQuery()

  const targetFilter = query.get('target') || 'all'
  const runFilter = query.get('run') || 'all'
  const defectFilter = query.get('type') || 'all'

  useEffect(() => {
    document.title = getProjectDefectsPageTitle(owner, projectSlug)
  }, [owner, projectSlug, targetFilter, runFilter, defectFilter])

  const { isLoading: isFetchingDefectsStats, data: defectStats } = useGetDefectStatsByProjectQuery({
    owner,
    projectSlug,
    target: targetFilter === 'all' ? undefined : targetFilter,
    run: runFilter === 'all' ? undefined : runFilter
  })

  // currently "active" defects mean the defects from the latest run
  const defectSet = runFilter === 'latest' ? defectStats?.active : defectStats?.total
  const defectTypes = defectSet?.breakdown.map((entry) => {
    return { name: entry.cwe_short_name, count: entry.count, cweNumber: entry.cwe_number, fullName: entry.cwe_name }
  })

  return (
    <Stack spacing={8}>
      <ProjectDefectsHeader nDefects={defectSet?.count} defectTypes={defectTypes} isFetching={isFetchingDefectsStats} defectFilter={defectFilter} />
      <ProjectDefectsList defectTypes={defectTypes} {...props} />
    </Stack>
  )
}
