import { Box, Container, Drawer, DrawerContent, DrawerOverlay, Grid, useBreakpointValue, useDisclosure } from '@chakra-ui/react'

import { ReactNode, memo } from 'react'

import { MobileNavbar } from './MobileNavbar'

const ShellInner = ({ navbar, sidebar, logo, children }: ShellProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { fallback: 'lg' })

  if (!isDesktop) {
    sidebar = (
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} isFullHeight preserveScrollBarGap>
        <DrawerOverlay />
        <DrawerContent>{sidebar}</DrawerContent>
      </Drawer>
    )
  }

  // TODO(kostas): remove 100vh when mayhem-web layout is fixed
  return (
    <Grid
      as="section"
      height="100vh"
      bg="bg-canvas"
      templateColumns={{ base: '1fr', lg: 'var(--chakra-sizes-2xs) 1fr' }}
      templateAreas={{
        base: `
          "mobile-navbar"
          "page"
        `,
        lg: `
          "sidebar page"
        `
      }}
      css={{
        '@media print': {
          // prevents a blank page at the start when printing from Firefox
          display: 'block'
        }
      }}
    >
      {sidebar}
      {!isDesktop && <MobileNavbar logo={logo} isOpen={isOpen} onToggle={onToggle} />}
      {navbar}
      <Container
        padding="0"
        flex="1"
        maxW={{ base: 'full', lg: 'unset' }}
        minW={0}
        gridArea="page"
        overflowY="auto"
        bg="page-bg"
        css={{
          '@media print': {
            overflow: 'visible',
            breakInside: 'avoid'
          }
        }}
      >
        <Box py={8} px={{ base: 4, lg: 8 }}>
          {children}
        </Box>
      </Container>
    </Grid>
  )
}

export const Shell = memo(ShellInner)
export interface ShellProps {
  navbar?: JSX.Element
  logo: JSX.Element // only used by the "mobile" navbar
  sidebar: JSX.Element
  alert?: JSX.Element
  children?: ReactNode
}
