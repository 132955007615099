import { useEffect } from 'react'

import { Link } from 'wouter'
import { Button, Card, CardBody, CardHeader, Heading, Icon, Skeleton, Stack, Text } from '@chakra-ui/react'
import ArrowCircleRightIcon from '@material-design-icons/svg/sharp/arrow_circle_right.svg?react'

import { skipToken } from '@reduxjs/toolkit/query'

import { getWorkspacePageTitle } from '../../titles'
import { Page } from '../../components/Page'

import { useGetAccountQuery, useGetOrgMemberQuery, useWhoamiQuery } from '../../redux/api/workspace'

import { ErrorPanel } from '../../components/ErrorPanel'

import { DataTab, DataTabs } from '../../components/DataTabs'

import { WebhooksTab } from './WebhooksTab'
import { TeamsTab } from './TeamsTab'
import { MembersTab } from './MembersTab'
import { GeneralTab } from './GeneralTab'

interface Props {
  workspace: string
}

export function WorkspaceSettingsPage({ workspace }: Props) {
  const { isLoading, isError, error, data } = useGetAccountQuery({ owner: workspace })

  const { data: profile } = useWhoamiQuery()
  const { data: currentMember } = useGetOrgMemberQuery(
    !profile
      ? skipToken
      : {
          owner: workspace,
          userSlug: profile.slug as string
        }
  )

  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'Settings')
  }, [workspace])

  if (isLoading) {
    return <Skeleton height="16rem" />
  }

  if (isError && 'status' in error) {
    const message = (error?.data as { message: string } | undefined)?.message
    return <ErrorPanel msg={message || 'Failed to access workspace'} />
  } else if (isError) {
    return <ErrorPanel msg="Failed to access workspace" />
  }

  const { is_admin: isAdmin } = { ...profile }
  const { is_org: isOrg } = { ...data }
  const { owns: isOwner } = { ...currentMember }

  const allowWrite = !!isOwner || !!isAdmin

  const readTabs: DataTab[] = [
    {
      label: 'General',
      content: <GeneralTab allowWrite={allowWrite} workspace={workspace} />,
      route: `/${workspace}/-/settings`
    },
    {
      label: 'Members',
      content: <MembersTab allowWrite={allowWrite} workspace={workspace} />,
      route: `/${workspace}/-/settings/members`
    }
  ]

  const writeTabs: DataTab[] = [
    {
      label: 'Teams',
      content: <TeamsTab allowWrite={allowWrite} workspace={workspace} />,
      route: `/${workspace}/-/settings/teams`
    },
    { label: 'Webhooks', content: <WebhooksTab workspace={workspace} />, route: `/${workspace}/-/settings/webhooks` }
  ]

  const tabs = allowWrite ? readTabs.concat(writeTabs) : readTabs

  if (isOrg) {
    return (
      <Page>
        <Stack spacing={8}>
          <Heading>Workspace Settings</Heading>
          <DataTabs variant="line" tabs={tabs} isLazy />
        </Stack>
      </Page>
    )
  } else {
    return (
      <Card variant="warning" mb={8}>
        <CardHeader>
          <Heading variant="cardHeading">Not available for Personal Workspace</Heading>
        </CardHeader>
        <CardBody p={4}>
          <Stack spacing={4}>
            <Text>Members can only be managed in a shared workspace.</Text>
            <Text>
              Please choose a different workspace, or{' '}
              <Button variant="link" rightIcon={<Icon as={ArrowCircleRightIcon} />}>
                <Link to="/-/settings/create-workspace">create a new workspace</Link>
              </Button>
              .
            </Text>
          </Stack>
        </CardBody>
      </Card>
    )
  }
}
