// import { Navbar } from "./Navbar";
import { Box, Flex } from '@chakra-ui/react'

import { ToggleButton } from './ToggleButton'

// the bar that appears on mobile to open the sidenav
export const MobileNavbar = ({ isOpen, onToggle, logo }: MobileNavbarProps) => {
  return (
    <Box width="full" py="4" px={{ base: '4', md: '8' }} gridArea="mobile-navbar">
      <Flex justify="space-between">
        {logo}
        <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
      </Flex>
    </Box>
  )
}

export interface MobileNavbarProps {
  logo: JSX.Element
  isOpen: boolean
  onToggle: () => void
}
