import { useLocation } from 'wouter'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading } from '@chakra-ui/react'

import { WorkersFullNotice } from '../runs/WorkersFullNotice'
import { AUTOREFRESH_INTERVAL } from '../../redux/api/mayhemApi'

import { Page } from '../../components/Page'
import { ProjectOverviewPage } from '../project-overview/ProjectOverviewPage'
import { useAppSelector, useQuery } from '../../hooks'
import { GITHUB_PROJECT_MISSING, handleAsyncResponseError } from '../../components/layout/ErrorPage'

import { DataTab, DataTabs } from '../../components/DataTabs'

import { ProjectSettingsWebhooksPage } from '../project-webhooks/ProjectSettingsWebhooksPage'

import { AccessControlPage } from '../project-accesscontrol/AccessControlPage'

import { ProjectSettingsGeneralPage } from '../project-settings/ProjectSettingsGeneralPage'

import { getCurrentUserInfo } from '../auth/utils'
import { useGetProjectMemberPermissionQuery, useGetProjectQuery } from '../../redux/api/projects'

import { ProjectDefectsPage } from './ProjectDefectsPage'
import { ProjectRunsPage } from './ProjectRunsPage'
import { ProjectBuildsPage } from './ProjectBuildsPage'

export function ProjectPage({ workspace, project }: Props) {
  const [location, setLocation] = useLocation()
  const query = useQuery()

  const { userSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { isLoading: projectMemberPermissionIsLoading, data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner: workspace,
    projectSlug: project,
    userSlug
  })

  const { isError, error } = useGetProjectQuery(
    { owner: workspace, projectSlug: project },
    { pollingInterval: !document.hidden ? AUTOREFRESH_INTERVAL : 0 }
  )

  const isAdmin = isMayhemAdmin || (!projectMemberPermissionIsLoading && projectPermission && projectPermission.permission === 'ADMIN')

  const fromGitHubUrl = query.get('fromGitHubUrl') || false

  if (isError) {
    handleAsyncResponseError(
      location,
      setLocation,
      fromGitHubUrl
        ? {
            response: {
              status: 404,
              json: {
                message: GITHUB_PROJECT_MISSING
              }
            }
          }
        : error
    )
  }

  const readTabs: DataTab[] = [
    {
      label: 'Overview',
      content: <ProjectOverviewPage owner={workspace} projectSlug={project} />,
      route: `/${workspace}/${project}`
    },
    {
      label: 'Defects',
      content: <ProjectDefectsPage owner={workspace} projectSlug={project} />,
      route: `/${workspace}/${project}/-/defects`
    },
    {
      label: 'Runs',
      content: <ProjectRunsPage owner={workspace} projectSlug={project} />,
      route: `/${workspace}/${project}/-/runs`
    },
    {
      label: 'Builds',
      content: <ProjectBuildsPage owner={workspace} projectSlug={project} />,
      route: `/${workspace}/${project}/-/builds`
    }
  ]

  const adminTabs = [
    {
      label: 'Webhooks',
      content: <ProjectSettingsWebhooksPage owner={workspace} projectSlug={project} />,
      route: `/${workspace}/${project}/-/settings/webhooks`
    },
    {
      label: 'Access Control',
      content: <AccessControlPage owner={workspace} projectSlug={project} />,
      route: `/${workspace}/${project}/-/settings/access-control`
    },
    {
      label: 'Settings',
      content: <ProjectSettingsGeneralPage owner={workspace} projectSlug={project} />,
      route: `/${workspace}/${project}/-/settings/general`
    }
  ]

  return (
    <Page>
      <WorkersFullNotice />
      <Breadcrumb mb={8}>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{project}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Heading mb={8}>{project}</Heading>

      <DataTabs isLazy variant="line" width="full" tabs={isAdmin ? readTabs.concat(adminTabs) : readTabs} />
    </Page>
  )
}

interface Props {
  workspace: string
  project: string
}
