import { Heading } from '@chakra-ui/react'

import { WorkersFullNotice } from '../runs/WorkersFullNotice'
import { Page } from '../../components/Page'

import { AdminSettings } from '../admin-settings/AdminSettings'
import { ReportingDashboardPage } from '../admin-reports/ReportingDashboardPage'
import { UsersList } from '../admin-users/UsersList'
import { AdminWebhooksPage } from '../admin-webhooks/AdminWebhooksPage'
import { DataTab, DataTabs } from '../../components/DataTabs'
import { IntegrationsAdminSettings } from '../admin-settings/IntegrationsAdminSettings'
import { AdminWorkspacesPage } from '../admin-settings/AdminWorkspacesPage'
import { AdminWorkersPage } from '../admin-workers/AdminWorkersPage'
import { AdminRunsPage } from '../runs/AdminRunsPage'

export function AdminPage() {
  const tabData: DataTab[] = [
    {
      label: 'Settings',
      content: <AdminSettings />,
      route: '/-/settings/admin'
    },
    {
      label: 'Reporting Dashboard',
      content: <ReportingDashboardPage />,
      route: `/-/settings/admin/reports`
    },
    {
      label: 'Users',
      content: <UsersList />,
      route: `/-/settings/admin/users`
    },
    {
      label: 'Workspaces',
      content: <AdminWorkspacesPage />,
      route: `/-/settings/admin/workspaces`
    },
    {
      label: 'Integrations',
      content: <IntegrationsAdminSettings />,
      route: `/-/settings/admin/integrations`
    },
    {
      label: 'Webhooks',
      content: <AdminWebhooksPage />,
      route: `/-/settings/admin/webhooks`
    },
    {
      label: 'Runs',
      content: <AdminRunsPage />,
      route: `/-/settings/admin/runs`
    },
    {
      label: 'Workers',
      content: <AdminWorkersPage />,
      route: '/-/settings/admin/workers'
    }
  ]

  return (
    <Page>
      <WorkersFullNotice />
      <Heading mb={8}>Admin Settings</Heading>

      <DataTabs isLazy variant="line" width="full" tabs={tabData} />
    </Page>
  )
}
