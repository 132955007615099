import { useCallback } from 'react'
import { Card, CardBody, CardFooter, CardHeader, HStack, Text } from '@chakra-ui/react'
import { useLocation } from 'wouter'

import { useGetTargetsByProjectQuery } from '../../redux/api/targets'

import ListDefaults from '../../components/ListDefaults'
import { MayhemPagination } from '../../components/MayhemPagination'
import { RunRow } from '../runs/RunRow'
import { AUTOREFRESH_INTERVAL, DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { stringifyUrl } from '../../util/location'
import { cleanInteger } from '../../util/numbers'

import { useQuery } from '../../hooks'

import { FilterMenuButton } from '../../components/FilterMenuButton'

import { useGetProjectRunsQuery } from '../../redux/api/projects'

import MayhemTableLoader from '../../components/MayhemTableLoader'
import { StatusParam, statusOptions } from '../runs/utils'

type Props = {
  owner: string
  projectSlug: string
}

export function ProjectRunsList({ owner, projectSlug }: Props) {
  const [location, setLocation] = useLocation()

  const query = useQuery()
  const targetFilter = query.get('target') || 'all'
  const status = (query.get('status') || 'all') as StatusParam
  const page = cleanInteger(query.get('page'), 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)

  const {
    currentData: projectRunsReponse,
    isFetching: isFetchingRuns,
    isError: isListLoadFail
  } = useGetProjectRunsQuery(
    {
      owner,
      projectSlug,
      perPage,
      offset: (page - 1) * perPage,
      target: targetFilter === 'all' ? undefined : targetFilter,
      status: status === 'all' ? undefined : status
    },
    { pollingInterval: AUTOREFRESH_INTERVAL }
  )
  const isLoadingRuns = isFetchingRuns && projectRunsReponse === undefined

  const { data: projectTargetsResponse, isLoading: isLoadingTargets } = useGetTargetsByProjectQuery({ owner, projectSlug, offset: 0, perPage: 99 })

  const selectTargetFilter = useCallback(
    (newSelectedTargetFilter: string | string[]): void => {
      if (newSelectedTargetFilter instanceof Array) {
        newSelectedTargetFilter = newSelectedTargetFilter[0]
      }
      if (targetFilter !== newSelectedTargetFilter) {
        const filter = newSelectedTargetFilter !== 'all' ? { target: newSelectedTargetFilter } : {}
        setLocation(stringifyUrl(`/${owner}/${projectSlug}/-/runs`, filter), { replace: true })
      }
    },
    [owner, projectSlug, setLocation, targetFilter]
  )

  if (isLoadingTargets) {
    return <MayhemTableLoader />
  }

  const { runs = [], count = 0 } = projectRunsReponse || {}
  const { targets = [] } = projectTargetsResponse || {}
  const checkedTargets = targets.map((target) => {
    return {
      key: target.slug || '',
      text: target.target_name,
      value: target.slug || ''
    }
  })

  const filterOptionsBase = [
    {
      key: 'All Targets',
      text: 'All Targets',
      value: 'all'
    }
  ]
  const filterOptions = filterOptionsBase.concat(checkedTargets)

  const checkedRuns = runs === undefined ? [] : runs

  const selectRunsFilter = (newStatusType: StatusParam | StatusParam[]): void => {
    if (newStatusType instanceof Array) {
      newStatusType = newStatusType[0]
    }

    setLocation(stringifyUrl(location, { status: newStatusType }), { replace: true })
  }

  return (
    <Card>
      <CardHeader>
        <HStack alignItems="baseline">
          <Text fontSize="xs" fontWeight="semibold" color="faded">
            FILTER:
          </Text>
          <FilterMenuButton options={filterOptions} defaultText="Filter By Target" value={targetFilter} onValueChange={selectTargetFilter} />
          <FilterMenuButton
            options={statusOptions}
            value={status}
            defaultText="All Runs"
            onValueChange={selectRunsFilter}
            isDisabled={isLoadingRuns}
          />
        </HStack>
      </CardHeader>
      <CardBody>
        {runs.map((run) => {
          return <RunRow key={run.run_id} run={run} owner={owner} />
        })}
        <ListDefaults isLoading={isLoadingRuns} nItems={checkedRuns.length} itemName="run" isFail={isListLoadFail} />
      </CardBody>
      <CardFooter justifyContent="center">
        <MayhemPagination isLoading={isLoadingRuns} total={count} pageSize={perPage} />
      </CardFooter>
    </Card>
  )
}
