import React from 'react'
import { Grid, GridItem, Icon, Text } from '@chakra-ui/react'

import { CardRow } from '../../components/Card/CardRow'
import PassFailCheck, { Result } from '../../components/PassFailCheck'

import DepIcon from '../../images/dep.svg?react'
import FortifyIcon from '../../images/fortify.svg?react'
import AslrIcon from '../../images/aslr.svg?react'
import StackGuardIcon from '../../images/stackcanaries.svg?react'

interface Props {
  type: string
  checked?: boolean
  action: React.ReactNode
  description: React.ReactNode
  taskFailed: boolean
}

export function ExploitabilityFactorsListRow({ type, checked, action, description, taskFailed }: Props) {
  const result = !taskFailed && checked !== undefined ? (checked ? Result.PASS : Result.FAIL) : Result.NONE

  let icon
  switch (type) {
    case 'DEP':
      icon = DepIcon
      break

    case 'FORTIFY_SOURCE':
      icon = FortifyIcon
      break

    case 'ASLR':
      icon = AslrIcon
      break

    case 'StackGuard':
      icon = StackGuardIcon
      break

    default:
      break
  }

  return (
    <CardRow disabled padding={0}>
      <Grid templateColumns={{ base: '100%', md: '10% 15% 15% 25% 35%' }} alignItems="center" justifyItems="center" gap={{ base: 4, md: 0 }}>
        <GridItem>
          <Icon as={icon} boxSize={8} fill="faded" />
        </GridItem>
        <GridItem width="full" textAlign={{ base: 'center', md: 'left' }}>
          <Text>{type}</Text>
        </GridItem>
        <GridItem>
          <PassFailCheck result={result} size="big" />
        </GridItem>
        <GridItem width="full" textAlign={{ base: 'center', md: 'left' }}>
          <Text>{action}</Text>
        </GridItem>
        <GridItem p={2}>
          <Text>{description}</Text>
        </GridItem>
      </Grid>
    </CardRow>
  )
}
