import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['mdsbom'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMdsbomImageStats: build.query<GetMdsbomImageStatsApiResponse, GetMdsbomImageStatsApiArg>({
        query: (queryArg) => ({ url: `/api/v2/workspace/${queryArg.workspace}/dsbom/image/${queryArg.imageId}/stats` }),
        providesTags: ['mdsbom']
      }),
      getMdsbomImageRepo: build.query<GetMdsbomImageRepoApiResponse, GetMdsbomImageRepoApiArg>({
        query: (queryArg) => ({ url: `/api/v2/workspace/${queryArg.workspace}/dsbom/image-repo/${queryArg.imageRepoId}` }),
        providesTags: ['mdsbom']
      }),
      getMdsbomImageRepoDefects: build.query<GetMdsbomImageRepoDefectsApiResponse, GetMdsbomImageRepoDefectsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/workspace/${queryArg.workspace}/dsbom/image-repo/${queryArg.imageRepoId}/defects`,
          params: { offset: queryArg.offset, ordered_by: queryArg.orderedBy, per_page: queryArg.perPage, sorted_by: queryArg.sortedBy }
        }),
        providesTags: ['mdsbom']
      }),
      getMdsbomImageRepoPackages: build.query<GetMdsbomImageRepoPackagesApiResponse, GetMdsbomImageRepoPackagesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/workspace/${queryArg.workspace}/dsbom/image-repo/${queryArg.imageRepoId}/packages`,
          params: {
            observed_only: queryArg.observedOnly,
            offset: queryArg.offset,
            ordered_by: queryArg.orderedBy,
            per_page: queryArg.perPage,
            sorted_by: queryArg.sortedBy
          }
        }),
        providesTags: ['mdsbom']
      }),
      getMdsbomImageRepoStats: build.query<GetMdsbomImageRepoStatsApiResponse, GetMdsbomImageRepoStatsApiArg>({
        query: (queryArg) => ({ url: `/api/v2/workspace/${queryArg.workspace}/dsbom/image-repo/${queryArg.imageRepoId}/stats` }),
        providesTags: ['mdsbom']
      }),
      getMdsbomImageRepoTags: build.query<GetMdsbomImageRepoTagsApiResponse, GetMdsbomImageRepoTagsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/workspace/${queryArg.workspace}/dsbom/image-repo/${queryArg.imageRepoId}/tags`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['mdsbom']
      }),
      getMdsbomWorkspaceImageRepos: build.query<GetMdsbomWorkspaceImageReposApiResponse, GetMdsbomWorkspaceImageReposApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/workspace/${queryArg.workspace}/dsbom/images`,
          params: { offset: queryArg.offset, ordered_by: queryArg.orderedBy, per_page: queryArg.perPage, sorted_by: queryArg.sortedBy }
        }),
        providesTags: ['mdsbom']
      }),
      getMdsbomWorkspacePackages: build.query<GetMdsbomWorkspacePackagesApiResponse, GetMdsbomWorkspacePackagesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/workspace/${queryArg.workspace}/dsbom/packages`,
          params: { offset: queryArg.offset, ordered_by: queryArg.orderedBy, per_page: queryArg.perPage, sorted_by: queryArg.sortedBy }
        }),
        providesTags: ['mdsbom']
      }),
      getMdsbomWorkspaceReports: build.query<GetMdsbomWorkspaceReportsApiResponse, GetMdsbomWorkspaceReportsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/workspace/${queryArg.workspace}/dsbom/report-summaries`,
          params: {
            defect_cves: queryArg.defectCves,
            image_name: queryArg.imageName,
            image_repo_id: queryArg.imageRepoId,
            image_tag: queryArg.imageTag,
            offset: queryArg.offset,
            packages: queryArg.packages,
            per_page: queryArg.perPage,
            severity_greater_than_equal: queryArg.severityGreaterThanEqual,
            severity_less_than_equal: queryArg.severityLessThanEqual
          }
        }),
        providesTags: ['mdsbom']
      }),
      getMdsbomWorkspaceDefects: build.query<GetMdsbomWorkspaceDefectsApiResponse, GetMdsbomWorkspaceDefectsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/workspace/${queryArg.workspace}/dsbom/defects`,
          params: {
            image_repo_id: queryArg.imageRepoId,
            image_tag: queryArg.imageTag,
            observed_only: queryArg.observedOnly,
            offset: queryArg.offset,
            per_page: queryArg.perPage,
            severity_greater_than_equal: queryArg.severityGreaterThanEqual,
            severity_less_than_equal: queryArg.severityLessThanEqual
          }
        }),
        providesTags: ['mdsbom']
      }),
      getMdsbomReportDefects: build.query<GetMdsbomReportDefectsApiResponse, GetMdsbomReportDefectsApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/workspace/${queryArg.workspace}/dsbom/report/${queryArg.reportId}/defects`,
          params: {
            observed_only: queryArg.observedOnly,
            offset: queryArg.offset,
            per_page: queryArg.perPage,
            severity_greater_than_equal: queryArg.severityGreaterThanEqual,
            severity_less_than_equal: queryArg.severityLessThanEqual
          }
        }),
        providesTags: ['mdsbom']
      }),
      getMdsbomWorkspaceStats: build.query<GetMdsbomWorkspaceStatsApiResponse, GetMdsbomWorkspaceStatsApiArg>({
        query: (queryArg) => ({ url: `/api/v2/workspace/${queryArg.workspace}/dsbom/stats` }),
        providesTags: ['mdsbom']
      }),
      getMdsbomReportPackages: build.query<GetMdsbomReportPackagesApiResponse, GetMdsbomReportPackagesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/workspace/${queryArg.workspace}/dsbom/report/${queryArg.reportId}/packages`,
          params: { observed_only: queryArg.observedOnly, offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['mdsbom']
      }),
      getMdsbomDerivedReportSet: build.query<GetMdsbomDerivedReportSetApiResponse, GetMdsbomDerivedReportSetApiArg>({
        query: (queryArg) => ({ url: `/api/v2/workspace/${queryArg.workspace}/dsbom/report/${queryArg.reportId}` }),
        providesTags: ['mdsbom']
      }),
      getMdsbomDefect: build.query<GetMdsbomDefectApiResponse, GetMdsbomDefectApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/workspace/${queryArg.workspace}/dsbom/image-repo/${queryArg.imageRepoId}/defect/${queryArg.defectId}`,
          params: { offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['mdsbom']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetMdsbomImageStatsApiResponse = /** status 200 Returns statistics from the latest report for the given image in the given
    workspace. The calculation relies on the sca derived report and is set to 0 in case
    its missing.

    Args:
        `workspace`: The name of the workspace containing the MDSBOM image to return
        stats for.
        `image_id`: The unique MDSBOM identifier of the MDSBOM image to return stats
        for.

    Returns:
        Statistics from the latest report for the given MDSBOM image.

    Raises:
        `NotFound`: HTTP 404. Raised if the given workspace doesn't contain an image
        with the given ID, or if the image has no associated derived reports.
        `NotImplemented`: HTTP 501. Raised if the MDSBOM Service is not configured on
        this Mayhem installation (the `MAYHEM_MDSBOM_SERVICE_URL` environment variable
        is not set/invalid).
     */ MdsbomImageStats
export type GetMdsbomImageStatsApiArg = {
  imageId: string
  workspace: string
}
export type GetMdsbomImageRepoApiResponse = /** status 200 Returns detailed information for the given image repository in the given
    workspace.

    Args:
        `workspace`: The name of the workspace containing the MDSBOM image repository to
        return information for.
        `image_repo_id`: The unique identifier of the image repository to return
        information for.

    Returns:
        Detailed information for the given image repository in the given workspace.

    Raises:
        `BadRequest`: HTTP 400. Raised if the given image repository ID is invalid.
        `NotFound`: HTTP 404. Raised if the given workspace doesn't contain the given
        image repository; if the image repository doesn't contain any images; or if an
        image in the image repository doesn't contain any derived reports.
        `NotImplemented`: HTTP 501. Raised if the MDSBOM Service is not configured on
        this Mayhem installation (the `MAYHEM_MDSBOM_SERVICE_URL` environment variable
        is not set/invalid).
     */ MdsbomImageRepo
export type GetMdsbomImageRepoApiArg = {
  imageRepoId: string
  workspace: string
}
export type GetMdsbomImageRepoDefectsApiResponse = /** status 200 Returns summary information about the MDSBOM defects in the given image
    repository, with the option to filter, paginate, and sort the results.

    Note that the workspace name is required solely to ensure the current user has
    access to the workspace that contains the image repository.

    Args:
        `workspace`: The name of the workspace containing the image repository to return
        defects for.
        `image_repo_id`: The unique identifier of the image repository to return defects
        for.

    Returns:
        The defects in the given image repository, filtered, paginated and sorted as
        requested.

    Raises:
        `NotImplemented`: HTTP 501. Raised if the MDSBOM Service is not enabled or is
        misconfigured on this Mayhem installation.
     */ MdsbomDefectSummaryList
export type GetMdsbomImageRepoDefectsApiArg = {
  imageRepoId: string
  workspace: string
  /** Offset in the result. */
  offset?: number
  /** The direction of the sort (asc or desc). */
  orderedBy?: string
  /** Number of elements per page. */
  perPage?: number
  /** The argument to be sorted by. */
  sortedBy?: string
}
export type GetMdsbomImageRepoPackagesApiResponse = /** status 200 Returns summary information about the MDSBOM packages in the given image
    repository, with the option to filter, paginate, and sort the results.

    Note that the workspace name is required solely to ensure the current user has
    access to the workspace that contains the image repository.

    Args:
        `workspace`: The name of the workspace containing the image repository to return
        packages for.
        `image_repo_id`: The unique identifier of the image repository to return
        packages for.
        `report_id`: The unique identifier of the derived report to return packages for.
        Not implemented currently
        `observed_only`: If true, only return packages that were observed in the report.
        `offset`: Pagination query param. Position in the total list of packages to
        start returning results from.
        `per_page`: Pagination query param. Maximum number of packages to return in a
        single response.
    Returns:
        The packages in the given image repository, filtered, paginated and sorted as
        requested.
    Raises:
        ImageRepoNotFoundError: HTTP 404. Raised if the given image repository ID is
        invalid.
        NoImageRepoImagesError: HTTP 404. Raised if the given workspace doesn't contain
        the given image repository.
     */ MdsbomPackageList
export type GetMdsbomImageRepoPackagesApiArg = {
  imageRepoId: string
  workspace: string
  observedOnly?: boolean
  /** Offset in the result. */
  offset?: number
  /** The direction of the sort (asc or desc). */
  orderedBy?: string
  /** Number of elements per page. */
  perPage?: number
  /** The argument to be sorted by. */
  sortedBy?: string
}
export type GetMdsbomImageRepoStatsApiResponse = /** status 200 Returns statistics for the given image repository in the given workspace. Image
    repository statistics are the sum of the latest derived report statistics for each
    image in the image repository.

    Args:
        `workspace`: The name of the workspace containing the image repository to return
        stats for.
        `image_repo_id`: The unique identifier of the image repository to return stats
        for.

    Returns:
        The latest image repository-wide statistics for the given image repository.

    Raises:
        `BadRequest`: HTTP 400. Raised if the given image repository ID is invalid.
        `NotFound`: HTTP 404. Raised if the given workspace doesn't contain the given
        image repository; if the image repository doesn't contain any images; or if an
        image in the image repository doesn't contain any derived reports.
        `NotImplemented`: HTTP 501. Raised if the MDSBOM Service is not configured on
        this Mayhem installation (the `MAYHEM_MDSBOM_SERVICE_URL` environment variable
        is not set/invalid).
     */ MdsbomImageRepoStats
export type GetMdsbomImageRepoStatsApiArg = {
  imageRepoId: string
  workspace: string
}
export type GetMdsbomImageRepoTagsApiResponse = /** status 200 Returns all the tags associated with the given image repository in the given
    workspace, with the option to paginate the results.

    Args:
        `workspace`: The name of the workspace containing the image repository to
        return tags for.
        `image_repo_id`: The unique identifier of the image repository to return tags
        for.
        `offset`: Pagination query param. Position in the total list of tags to start
        returning results from.
        `per_page`: Pagination query param. Maximum number of tags to return in a
        single response.

    Returns:
        All the tags associated with the given MDSBOM image repository in the given
        workspace, paginated as requested.

    Raises:
        `BadRequest`: HTTP 400. Raised if the given image repository ID is invalid, or
        if any of the pagination query parameters are invalid or out of range.
        `NotFound`: HTTP 404. Raised if the given workspace doesn't contain the given
        image repository.
        `NotImplemented`: HTTP 501. Raised if the MDSBOM Service is not configured on
        this Mayhem installation (the `MAYHEM_MDSBOM_SERVICE_URL` environment variable
        is not set/invalid).
     */ MdsbomImageRepoTagList
export type GetMdsbomImageRepoTagsApiArg = {
  imageRepoId: string
  workspace: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type GetMdsbomWorkspaceImageReposApiResponse = /** status 200 Returns summary information about the MDSBOM image repositories in the given
    workspace, with the option to paginate and sort the results.

    Note that the workspace name is required solely to ensure the current user has
    access to the workspace that contains the image repositories.

    Args:
        `workspace`: URL param. The name of the workspace the MDSBOM image repositories
        belong to.
        `offset`: Pagination query param. Position in the total list of image repository
        results to start returning results from.
        `per_page`: Pagination query param. Maximum number of image repositories to
        return in a single response.
        `sorted_by`: Sorting query param; currently unused. Name of the field to sort
        the image repositories by.
        `ordered_by`: Sorting query param; currently unused. Direction to sort the image
        repositories in (either "asc" or "desc").

    Returns:
        A list of the MDSBOM image repositories in the given workspace, paginated and
        sorted as requested.

    Raises:
        `BadRequest`: HTTP 400. Raised if any of the pagination or sorting query
        parameters are invalid or out of range.
        `NotFound`: HTTP 404. Raised if the given workspace doesn't contain any image
        repositories, or if an image repository in the workspace is missing images or
        derived reports.
        `NotImplemented`: HTTP 501. Raised if the MDSBOM Service is not configured on
        this Mayhem installation (the `MAYHEM_MDSBOM_SERVICE_URL` environment variable
        is not set/invalid).
     */ MdsbomImageRepoList
export type GetMdsbomWorkspaceImageReposApiArg = {
  workspace: string
  /** Offset in the result. */
  offset?: number
  /** The direction of the sort (asc or desc). */
  orderedBy?: string
  /** Number of elements per page. */
  perPage?: number
  /** The argument to be sorted by. */
  sortedBy?: string
}
export type GetMdsbomWorkspacePackagesApiResponse = /** status 200 Returns all the packages in a given workspace.

    Note that the workspace name is required solely to ensure the current user has
    access to the package.

    Args:
        `workspace`: The name of the workspace the MDSBOM packages belong to.

    Returns:
        All the packages associated with the given workspace (if any).

    Raises:
        `NotImplemented`: HTTP 501. Raised if the MDSBOM Service is not enabled or is
        misconfigured on this Mayhem installation.
     */ MdsbomPackageList
export type GetMdsbomWorkspacePackagesApiArg = {
  workspace: string
  /** Offset in the result. */
  offset?: number
  /** The direction of the sort (asc or desc). */
  orderedBy?: string
  /** Number of elements per page. */
  perPage?: number
  /** The argument to be sorted by. */
  sortedBy?: string
}
export type GetMdsbomWorkspaceReportsApiResponse = /** status 200 Returns summary information about the MDSBOM reports in the given workspace,
    with the option to filter, paginate, and sort the results. */ MdsbomReportSummaryList
export type GetMdsbomWorkspaceReportsApiArg = {
  workspace: string
  defectCves?: string[]
  imageName?: string
  imageRepoId?: string
  imageTag?: string
  /** Offset in the result. */
  offset?: number
  packages?: string[]
  /** Number of elements per page. */
  perPage?: number
  severityGreaterThanEqual?: number
  severityLessThanEqual?: number
}
export type GetMdsbomWorkspaceDefectsApiResponse = /** status 200 Returns summary information about the MDSBOM defects in the given workspace,
    with the option to filter, paginate, and sort the results. */ MdsbomDefectSummaryList
export type GetMdsbomWorkspaceDefectsApiArg = {
  workspace: string
  imageRepoId?: string
  imageTag?: string
  observedOnly?: boolean
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  severityGreaterThanEqual?: number
  severityLessThanEqual?: number
}
export type GetMdsbomReportDefectsApiResponse = /** status 200 Returns summary information about the MDSBOM defects in the given workspace,
    with the option to filter, paginate, and sort the results. */ MdsbomDefectSummaryList
export type GetMdsbomReportDefectsApiArg = {
  reportId: string
  workspace: string
  observedOnly?: boolean
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
  severityGreaterThanEqual?: number
  severityLessThanEqual?: number
}
export type GetMdsbomWorkspaceStatsApiResponse = /** status 200 Returns workspace-wide statistics for the given workspace. Workspace statistics
    are the sum of the latest report statistics for each image in the workspace. The
    calculation relies on the sca derived report and is set to 0 in case its missing.

    Note that the workspace name is required solely to ensure the current user has
    access to the workspace.

    Args:
        `workspace`: The name of the workspace to return statistics for.

    Returns:
        Workspace-wide statistics for the given workspace.

    Raises:
        `NotImplemented`: HTTP 501. Raised if the MDSBOM Service is not enabled or is
        misconfigured on this Mayhem installation.
     */ MdsbomWorkspaceStats
export type GetMdsbomWorkspaceStatsApiArg = {
  workspace: string
}
export type GetMdsbomReportPackagesApiResponse = /** status 200
    Returns a list of packages associated with a given MDSBOM report, as well as the
    total count after the filters are applied.

    Args:
        `workspace`: The name of the workspace containing the report.
        `report_id`: The unique MDSBOM identifier of the report.
        `observed_only`: If true, only packages observed in the report will be returned.
        `per_page`: The maximum number of packages to return.
        `offset`: The number of packages to skip before returning results.

    Returns:
        A list of packages associated with the report, as well as the total count after
        the filters are applied.

    Raises:
        `NotImplemented`: HTTP 501. Raised if the MDSBOM Service is not enabled or is
        misconfigured on this Mayhem installation.
     */ MdsbomReportPackageList
export type GetMdsbomReportPackagesApiArg = {
  reportId: string
  workspace: string
  observedOnly?: boolean
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type GetMdsbomDerivedReportSetApiResponse = /** status 200
    Returns the derived report set for the given report_id.

    Returns:
        The reports associated with the given report set. This currently includes 4
        reports:
            1. The derived SBOM report
            2. The derived SCA report
            3. The source SBOM report
            4. The source SCA report

        These reports are meant to be annotated in the future, thus the differentation
        between derived and source.

     */ MdsbomDerivedReportSet
export type GetMdsbomDerivedReportSetApiArg = {
  reportId: string
  workspace: string
}
export type GetMdsbomDefectApiResponse = /** status 200
    Returns the defect with the given defect_id.
     */ MdsbomDefect
export type GetMdsbomDefectApiArg = {
  defectId: string
  imageRepoId: string
  workspace: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type MdsbomImageStats = {
  n_defects_observed: number
  n_defects_observed_high: number
  n_defects_observed_medium: number
  n_defects_observed_low: number
  n_defects_reported: number
  n_defects_reported_high: number
  n_defects_reported_medium: number
  n_defects_reported_low: number
  image_id: string
}
export type ApiError = {
  message?: string
}
export type MdsbomImageRepo = {
  id: string
  repo_url: string
  registry_url?: string | null
  image_name: string
  last_updated: string
  observed_defects: number
}
export type MdsbomPackageSynopsis = {
  package_name: string
  package_url: string
}
export type MdsbomDefectSummary = {
  id: string
  title: string
  created: string
  image_registry?: string | null
  image_repo: string
  image_repo_id: string
  image_tag?: string | null
  observed: boolean
  affected_packages: MdsbomPackageSynopsis[]
  severity?: number | null
  severity_level: string
}
export type MdsbomDefectSummaryList = {
  count: number
  defects: MdsbomDefectSummary[]
}
export type MdsbomPackage = {
  id: string
  name: string
  namespace?: string | null
  purl?: string | null
  qualifiers?: {
    [key: string]: string
  } | null
  subpath?: string | null
  type_?: string | null
  version: string
  was_observed?: boolean | null
  vulnerability_count?: number | null
}
export type MdsbomPackageList = {
  count: number
  packages: MdsbomPackage[]
}
export type MdsbomImageRepoStats = {
  n_defects_observed: number
  n_defects_observed_high: number
  n_defects_observed_medium: number
  n_defects_observed_low: number
  n_defects_reported: number
  n_defects_reported_high: number
  n_defects_reported_medium: number
  n_defects_reported_low: number
}
export type MdsbomImageRepoTagList = {
  count: number
  tags: string[]
}
export type MdsbomImageRepoList = {
  count: number
  image_repos: MdsbomImageRepo[]
}
export type MdsbomReportSummary = {
  n_defects_observed: number
  n_defects_observed_high: number
  n_defects_observed_medium: number
  n_defects_observed_low: number
  n_defects_reported: number
  n_defects_reported_high: number
  n_defects_reported_medium: number
  n_defects_reported_low: number
  id: string
  created: string
  image_references?: string[] | null
}
export type MdsbomReportSummaryList = {
  count: number
  reports: MdsbomReportSummary[]
}
export type MdsbomWorkspaceStats = {
  n_defects_observed: number
  n_defects_observed_high: number
  n_defects_observed_medium: number
  n_defects_observed_low: number
  n_defects_reported: number
  n_defects_reported_high: number
  n_defects_reported_medium: number
  n_defects_reported_low: number
  workspace_name: string
}
export type MdsbomReportPackageList = {
  count: number
  packages: MdsbomPackage[]
}
export type MdsbomDerivedReportSet = {
  n_defects_observed: number
  n_defects_observed_high: number
  n_defects_observed_medium: number
  n_defects_observed_low: number
  n_defects_reported: number
  n_defects_reported_high: number
  n_defects_reported_medium: number
  n_defects_reported_low: number
  title: string
  created_at: string
  image_reference?: string | null
  image_hash_id?: string | null
  annotated_sbom_report_id: string
  annotated_sca_report_id?: string | null
  source_sbom_report_id?: string | null
  source_sca_report_id?: string | null
}
export type MdsbomDefect = {
  id: string
  title: string
  created: string
  image_registry?: string | null
  image_repo: string
  image_repo_id: string
  image_tag?: string | null
  observed: boolean
  affected_packages: MdsbomPackageSynopsis[]
  severity?: number | null
  severity_level: string
  cve_url?: string | null
  description?: string | null
  references?: string[] | null
  derived_report_sets?: MdsbomReportSummaryList | null
  last_derived_report_set?: MdsbomReportSummary | null
}
