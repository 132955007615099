import { useEffect } from 'react'

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Skeleton, Stack } from '@chakra-ui/react'
import { Link as WouterLink } from 'wouter'

import { ErrorPanel } from '../../components/ErrorPanel'
import { Page } from '../../components/Page'
import { TruncatedTextWithTooltip } from '../../components/TruncatedTextWithTooltip'
import { useGetDefectByTargetQuery } from '../../redux/api/defects'
import { getDefectPageTitle } from '../../titles'

import { CodeDefectSummary } from './CodeDefectSummary'
import { DefectNavigation } from './DefectNavigation'
import { DefectsRelatedTestCases } from './DefectsRelatedTestCases'
import { DefectsRelatedValgrindErrors } from './DefectsRelatedValgrindErrors'
import { RestIssue } from './RestIssue'

interface Props {
  workspace: string
  project: string
  target: string
  defect: string
  runNumber: number | null | undefined
}

export function DefectPage({ workspace, project: projectSlug, target: targetSlug, defect: defectNumberParam, runNumber }: Props) {
  let defectNumber: number
  try {
    defectNumber = Number.parseInt(defectNumberParam, 10)
  } catch {
    throw new Error(`Invalid defect number: ${defectNumberParam}`)
  }

  const { data: defect, isLoading, isError } = useGetDefectByTargetQuery({ owner: workspace, projectSlug, targetSlug, defectNumber })
  useEffect(() => {
    document.title = getDefectPageTitle(workspace, projectSlug, targetSlug, defectNumberParam)
  }, [workspace, projectSlug, targetSlug, defectNumber, defectNumberParam])

  if (isLoading) {
    return <Skeleton />
  }

  const isMapiIssue = defect?.type === 'mapi' || defect?.type === 'zap'
  const isCodeIssue = defect?.type === 'crash' || defect?.type === undefined || defect?.type === 'valgrind'
  const isValgrindIssue = defect?.type === 'valgrind' || defect?.from_valgrind
  return (
    <Page>
      <HStack justifyContent="space-between" alignItems="flex-start">
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }} mb={8}>
          <BreadcrumbItem>
            <WouterLink to={`/${workspace}/${projectSlug}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={projectSlug} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <WouterLink to={`/${workspace}/${projectSlug}/${targetSlug}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={targetSlug} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Defect #{defectNumber}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <DefectNavigation owner={workspace} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} defectNumber={defectNumber} />
      </HStack>
      <Stack spacing={8}>
        {isError && <ErrorPanel msg="We can't find that defect for you. Sorry about that!" />}
        {isCodeIssue && <CodeDefectSummary owner={workspace} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />}
        {isCodeIssue && !isValgrindIssue && (
          <DefectsRelatedTestCases owner={workspace} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />
        )}
        {isValgrindIssue && (
          <DefectsRelatedValgrindErrors owner={workspace} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />
        )}
        {isMapiIssue && (
          <RestIssue owner={workspace} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} runNumber={runNumber} />
        )}
      </Stack>
    </Page>
  )
}
