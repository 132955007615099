import { useEffect } from 'react'

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Th,
  Tr,
  Thead,
  Stack,
  Box
} from '@chakra-ui/react'

import { ApiTargetRow } from '../target/ApiTargetRow'
import { CodeTargetRow } from '../target/CodeTargetRow'
import { MayhemPagination } from '../../components/MayhemPagination'
import { getProjectPageTitle } from '../../titles'
import { cleanInteger } from '../../util/numbers'
import { useQuery } from '../../hooks'
import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { useGetTargetsByProjectQuery } from '../../redux/api/targets'
import MayhemTableLoader from '../../components/MayhemTableLoader'
import { CardDefectTrend } from '../../components/CardDefectTrend'

import { ProjectOverviewStats } from './ProjectOverviewStats'

type Props = {
  owner: string
  projectSlug: string
}

export function ProjectOverviewPage(props: Props) {
  const { owner, projectSlug } = props

  const query = useQuery()
  const apiTargetsPage = Math.max(0, cleanInteger(query.get('apiTargets_page')) - 1)
  const codeTargetsPage = Math.max(0, cleanInteger(query.get('codeTargets_page')) - 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)

  const { isFetching: isApiTargetsFetching, currentData: apiTargetsData } = useGetTargetsByProjectQuery({
    owner: props.owner,
    projectSlug: props.projectSlug,
    matchingType: 'api',
    offset: apiTargetsPage * perPage,
    perPage
  })
  const apiTargets = apiTargetsData?.targets || []

  const { isFetching: isCodeTargetsFetching, currentData: codeTargetsData } = useGetTargetsByProjectQuery({
    owner: props.owner,
    projectSlug: props.projectSlug,
    matchingType: 'code',
    offset: codeTargetsPage * perPage,
    perPage
  })
  const codeTargets = codeTargetsData?.targets || []

  useEffect(() => {
    document.title = getProjectPageTitle(owner, projectSlug)
  }, [owner, projectSlug])

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, xl: 2 }} gap={6} mb={8}>
        <CardDefectTrend workspace={owner} project={projectSlug} />
        <ProjectOverviewStats owner={owner} projectSlug={projectSlug} />
      </SimpleGrid>

      <Stack spacing={8}>
        {!isApiTargetsFetching && apiTargets.length > 0 && (
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">API Targets</Heading>
            </CardHeader>
            <CardBody>
              <TableContainer>
                <Table id="api-target-table" sx={{ tableLayout: 'fixed', fontSize: 'sm' }}>
                  <Thead display={{ base: 'none', md: 'table-header-group' }}>
                    <Tr>
                      <Th width="60%" borderBottomColor="border">
                        Target Name
                      </Th>
                      <Th width="20%" borderBottomColor="border">
                        Last Assessed
                      </Th>
                      <Th width="20%" borderBottomColor="border">
                        Defects
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {apiTargets.map((target) => (
                      <ApiTargetRow key={target.target_id} {...target} />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </CardBody>
            <CardFooter justifyContent="center">
              <MayhemPagination isLoading={isApiTargetsFetching} total={apiTargetsData?.count || 0} pageSize={perPage} prefix="apiTargets_" />
            </CardFooter>
          </Card>
        )}

        {!isCodeTargetsFetching && codeTargets.length > 0 && (
          <Card>
            <CardHeader>
              <Heading variant="cardHeading">Code Targets</Heading>
            </CardHeader>
            <CardBody>
              {isCodeTargetsFetching ? (
                <MayhemTableLoader />
              ) : (
                <TableContainer>
                  <Table id="api-target-table" sx={{ tableLayout: 'fixed', fontSize: 'sm' }}>
                    <Thead display={{ base: 'none', md: 'table-header-group' }}>
                      <Tr>
                        <Th width="40%" borderBottomColor="border">
                          Target Name
                        </Th>
                        <Th width="20%" borderBottomColor="border">
                          Last Assessed
                        </Th>
                        <Th width="20%" borderBottomColor="border">
                          Testing
                        </Th>
                        <Th width="20%" borderBottomColor="border">
                          Defects
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {codeTargets.map((target) => (
                        <CodeTargetRow key={target.target_id} {...target} />
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </CardBody>
            <CardFooter justifyContent="center">
              <MayhemPagination isLoading={isCodeTargetsFetching} total={codeTargetsData?.count || 0} pageSize={perPage} prefix="codeTargets_" />
            </CardFooter>
          </Card>
        )}
      </Stack>
    </Box>
  )
}
