import { mayhemApi as api } from './mayhemApi'
export const addTagTypes = ['target'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getTargetsByProject: build.query<GetTargetsByProjectApiResponse, GetTargetsByProjectApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target`,
          params: { matching_type: queryArg.matchingType, offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['target']
      }),
      postTarget: build.mutation<PostTargetApiResponse, PostTargetApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target`,
          method: 'POST',
          body: queryArg.target
        }),
        invalidatesTags: ['target']
      }),
      getTarget: build.query<GetTargetApiResponse, GetTargetApiArg>({
        query: (queryArg) => ({ url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}` }),
        providesTags: ['target']
      }),
      deleteTarget: build.mutation<DeleteTargetApiResponse, DeleteTargetApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}`,
          method: 'DELETE'
        }),
        invalidatesTags: ['target']
      }),
      getTargetBranches: build.query<GetTargetBranchesApiResponse, GetTargetBranchesApiArg>({
        query: (queryArg) => ({
          url: `/api/v2/owner/${queryArg.owner}/project/${queryArg.projectSlug}/target/${queryArg.targetSlug}/branches`,
          params: { filter_substring: queryArg.filterSubstring, offset: queryArg.offset, per_page: queryArg.perPage }
        }),
        providesTags: ['target']
      })
    }),
    overrideExisting: false
  })
export { injectedRtkApi as api }
export type GetTargetsByProjectApiResponse = /** status 200 Returns list of targets within the given project.

    Returns:
        list(Target) -- list of target objects in the project.
     */ TargetList
export type GetTargetsByProjectApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  matchingType?: 'code' | 'api'
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type PostTargetApiResponse = /** status 200 Creates a new target in the given project.

    Returns:
        Target -- The target object that was created.
     */ Target
export type PostTargetApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  target: Target
}
export type GetTargetApiResponse = /** status 200 Retrieves a single target that matches the given
    owner/project/target names.

    Returns:
        Target -- The matching target.
     */ Target
export type GetTargetApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type DeleteTargetApiResponse = /** status 202 Deletes a single target from a owner.

    Returns:
        {"result": "ok"} -- On successful deletion.
     */ Ok
export type DeleteTargetApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
}
export type GetTargetBranchesApiResponse = /** status 200 Returns a paged list of SCM branches that have been detected across all the runs
    in this target. An additional query can be provided to filter the list.

    Returns:
        A Paged list of SCM branches
     */ BranchList
export type GetTargetBranchesApiArg = {
  /** The owner under which a project is categorized under. For example, a user's username. */
  owner: string
  /** The name of the project. Note: the project name needs to be specified as a 'slug', a URL-friendly version of the project name. */
  projectSlug: string
  /** The name of the target. Note: the target name needs to be specified as a 'slug', a URL-friendly version of the target name. */
  targetSlug: string
  filterSubstring?: string
  /** Offset in the result. */
  offset?: number
  /** Number of elements per page. */
  perPage?: number
}
export type RunPhase = {
  phase_name?: string
  status?: string
  preparing_at?: string | null
  started_at?: string | null
  finalizing_at?: string | null
  ended_at?: string | null
  failed?: boolean
  stopped?: boolean
  pending_status?: string
  smoketesting_succeeded?: boolean | null
  duration?: number | null
  elapsed_real_time?: number | null
  msg?: string
}
export type RunPhaseCurrent = {
  phase_name?: string
  status?: string
  preparing_at?: string | null
  started_at?: string | null
  finalizing_at?: string | null
  ended_at?: string | null
  failed?: boolean
  stopped?: boolean
  pending_status?: string
  smoketesting_succeeded?: boolean | null
  duration?: number | null
  elapsed_real_time?: number | null
  msg?: string
  name?: string
  description?: string
  value?: number | null
}
export type EndpointCoverageV1Entry = {
  method: string
  path: string
  status?: number
  hits: number
  tags: string[]
}
export type EndpointCoverageV1 = {
  entries: EndpointCoverageV1Entry[]
}
export type EndpointCoverage = {
  V1: EndpointCoverageV1
}
export type EndpointLatencyV1Entry = {
  method: string
  path: string
  buckets: number[]
  hits: number[]
  tags: string[]
}
export type EndpointLatencyV1 = {
  entries: EndpointLatencyV1Entry[]
}
export type EndpointLatency = {
  V1: EndpointLatencyV1
}
export type GetApiRun = {
  created_at?: string
  updated_at?: string
  duration_mode: 'AUTOMATIC' | 'TIME' | 'GOAL'
  spec_source?: 'OPENAPI' | 'HAR_OPENAPI' | 'POSTMANCOLLECTION_OPENAPI' | 'SWAGGERV2_OPENAPI' | 'POSTMANCOLLECTION' | 'REPLAY' | 'UNKNOWN'
  url?: string
  progress_sec: number
  progress_percent: number
  status: 'PENDING' | 'RUNNING' | 'COMPLETE' | 'CANCELLED' | 'FAILED'
  total_endpoint_count?: number
  unauthed_endpoint_count?: number
  unsuccessful_endpoint_count?: number
  latency_p50_millis?: number
  latency_p90_millis?: number
  latency_p99_millis?: number
  endpoint_coverage?: EndpointCoverage
  endpoint_latency?: EndpointLatency
  sarif_sha?: string
  junit_sha?: string
  html_report_sha?: string
  reports_zip_sha?: string
}
export type RunGet = {
  created_at?: string
  updated_at?: string
  run_id: number
  run_number?: number
  target_id: number
  is_deleted?: boolean
  build_id?: string
  duration?: number | null
  task_id?: string
  tests_run_symexec?: number
  tests_run_mfuzz?: number
  tests_run_libfuzz?: number
  tests_run_honggfuzz?: number
  tests_per_second?: number
  edges_covered?: number
  run_attributes?: object | null
  cli_version?: string | null
  ci_type?: string | null
  ci_url?: string | null
  branch_name?: string | null
  merge_base_branch_name?: string | null
  parent_revision?: string | null
  revision?: string | null
  scm_remote_origin?: string | null
  scm_commit_author?: string | null
  scm_commit_author_email?: string | null
  scm_commit_subject?: string | null
  interface_info?: object
  cputime?: number
  elapsed_real_time?: number | null
  docker_registry_id?: number | null
  docker_pullable?: string
  fetched_docker_image_digest?: string
  base_testsuite_id?: number | null
  user_testsuite_id?: number | null
  output_testsuite_id?: number | null
  is_pure_docker?: boolean
  advanced_triage_run_valgrind?: boolean
  current_workers?: number
  desired_workers?: number
  n_total_memory?: number
  n_total_cpu?: number
  n_desired_memory?: number
  n_desired_cpu?: number
  started_by?: string
  is_mayhem_bot?: boolean
  n_starting_testcases?: number | null
  docker_registry_url?: string
  phases?: RunPhase[]
  tests_run?: number
  n_testcase_reports?: number
  n_behavior_testcase_reports?: number
  n_regression_testcase_reports?: number
  n_regression_defect_reports?: number
  n_crash_reports?: number
  n_behavior_crash_reports?: number
  n_regression_crash_reports?: number
  n_defects?: number
  n_behavior_defects?: number
  n_regression_defects?: number
  n_valgrind_errors?: number
  n_behavior_valgrind_errors?: number
  n_regression_valgrind_errors?: number
  n_exploitability_factors_passing?: number
  n_exploitability_factors_total?: number
  started_at?: string | null
  ended_at?: string | null
  pending?: boolean
  started?: boolean
  stopped?: boolean
  ended?: boolean
  failed?: boolean
  has_critical_errors?: boolean
  description?: string
  name?: string
  phase?: RunPhaseCurrent
  owner?: string
  owner_name?: string
  target_slug?: string
  target_name?: string
  project_slug?: string
  project_name?: string
  api_run?: GetApiRun
}
export type Target = {
  created_at?: string
  updated_at?: string
  target_id?: number
  project_id?: number
  owner?: string
  project_slug?: string
  slug?: string
  n_runs?: number
  target_name: string
  is_deleted?: boolean
  latest_active_run?: RunGet | null
  type?: 'code' | 'api'
  url?: string | null
}
export type TargetList = {
  targets?: Target[]
  count?: number
}
export type ApiError = {
  message?: string
}
export type Ok = {
  result?: string
}
export type Branch = {
  branch_name: string
}
export type BranchList = {
  branches?: Branch[]
  count: number
}
