// these can be used for semantically naming colors if we want
export const semanticTokens = {
  colors: {
    // matches --chakra-colors-chakra-body-bg
    body: {
      default: '#FFFFFF',
      _dark: 'gray.800'
    },

    'page-bg': {
      default: 'gray.25',
      _dark: 'gray.825'
    },

    // Override some chakra variables
    // to have global reach over basic variables
    // see https://github.com/chakra-ui/chakra-ui/blob/05f922f2fada732f04d93e13bd53434805f4d619/packages/theme/src/semantic-tokens.ts
    // used to have some overrides here, leaving the above link in case any are needed again in the future
    // (although in testing today this doesn't actually seem to work)

    // matches --chakra-colors-chakra-body-text
    primaryText: { default: 'gray.800', _dark: 'whiteAlpha.900' },
    // tweaked default Chakra body font colors
    faded: { default: 'blackAlpha.600', _dark: 'whiteAlpha.700' },

    // semantic colors
    bad: { default: 'bad.500', _dark: 'bad.200' },
    good: { default: 'success.500', _dark: 'success.200' },
    warning: { default: 'yellow.500', _dark: 'yellow.200' },
    error: { default: 'orange.500', _dark: 'orange.200' },
    severityHigh: { default: 'severityHigh.500', _dark: 'severityHigh.200' },
    severityMedium: { default: 'severityMedium.500', _dark: 'severityMedium.200' },
    severityLow: { default: 'severityLow.500', _dark: 'severityLow.200' },
    graphColor1: { default: '#EC000E', _dark: '#D60011' },
    graphColor2: { default: '#FFBB44', _dark: '#E7A941' },
    graphColor3: { default: '#DCFF10', _dark: '#C7E612' },
    graphColor4: { default: '#F26A8D', _dark: '#DB6083' },
    graphColor5: { default: '#2BB7E3', _dark: '#28A5D0' },
    graphColor6: { default: '#1B998B', _dark: '#1A8A81' },
    subtleBg: { default: 'gray.100', _dark: 'gray.800' },

    chartSeries1: { default: 'brand.600', _dark: 'brand.500' },
    chartSeries2: { default: 'brand.400', _dark: 'brand.300' },
    chartAxis: { default: 'gray.600', _dark: 'gray.400' },

    codeComment: { default: 'green.700', _dark: 'green.400' },
    code: { default: 'brand.700', _dark: 'brand.400' },

    accent: {
      default: 'brand.500',
      _dark: 'brand.200'
    },
    'bg-accent': 'body-dark',
    'bg-accent-subtle': 'brand.800',
    'on-accent': 'white',
    'on-accent-muted': 'brand.50',
    'on-accent-subtle': 'brand.100',
    'muted-accent': {
      default: 'brand.600',
      _dark: 'brand.100'
    },

    hoverBg: {
      default: 'gray.50',
      _dark: 'gray.500'
    },
    activeBg: {
      default: 'gray.200',
      _dark: 'gray.600'
    },
    selectedBg: {
      default: 'gray.100',
      _dark: 'gray.700'
    }
  }
}
