import { Card, CardBody, CardHeader, Heading, Link as ChakraLink, ListItem, OrderedList, Stack, Text, CardFooter } from '@chakra-ui/react'
import { Link as WouterLink } from 'wouter'

import { CodeBlock } from '../../components/CodeBlock/CodeBlock'
import { Page } from '../../components/Page'
import { getDocsUrl } from '../../urls'

interface Props {
  workspace: string
}

export function DsbomGettingStartedPage({ workspace }: Props) {
  return (
    <Page>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Your First Dynamic SBOM Report</Heading>
        </CardHeader>
        <CardBody p={4}>
          <OrderedList spacing={8}>
            <ListItem>
              Install the{' '}
              <ChakraLink isExternal href={getDocsUrl('dynamic-sbom/installation/')} textDecoration="underline">
                Mayhem Dynamic SBOM CLI
              </ChakraLink>
              .
            </ListItem>
            <ListItem>
              <Stack spacing={2}>
                <Text>Configure the container engine used for your application:</Text>
                <CodeBlock>mdsbom configure-container-engine</CodeBlock>
              </Stack>
            </ListItem>
            <ListItem>
              <Stack spacing={2}>
                <Text>Run the mdsbom report command for your desired container image, SCA report, and SBOM report:</Text>
                <CodeBlock>
                  mdsbom report --sbom-report-source sbom_in.spdx.json --sca-report-source sca_in.cyclonedx.json --sbom-report-out sbom_out.spdx.json
                  --sca-report-source sca_in.cyclonedx.json image
                </CodeBlock>
              </Stack>
            </ListItem>
            <ListItem>
              Examine the results under{' '}
              <ChakraLink as={WouterLink} to={`/${workspace}/-/dynamic-sbom/-/reports`} textDecoration="underline">
                Dynamic SBOM - Reports
              </ChakraLink>
              .
            </ListItem>
          </OrderedList>
        </CardBody>
        <CardFooter justifyContent="end">
          <ChakraLink href={getDocsUrl('dynamic-sbom/summary/')} isExternal textDecoration="underline">
            Visit our Documentation for more detailed instructions and help getting started
          </ChakraLink>
        </CardFooter>
      </Card>
    </Page>
  )
}
