import { DefectsBySeverityRow } from '../defect-tag/DefectsBySeverityRow'

export interface TargetListingRowDefectsProps {
  latestActiveRun?: {
    run_attributes?: object | null
  }
}

export const TargetListingRowDefects = ({ latestActiveRun }: TargetListingRowDefectsProps) => {
  if (!latestActiveRun) {
    return <>?</>
  }
  const runAttributes = (latestActiveRun?.run_attributes || {}) as {
    n_defects_active_high?: number
    n_defects_active_medium?: number
    n_defects_active_low?: number
  }
  return (
    <DefectsBySeverityRow
      high={runAttributes.n_defects_active_high || 0}
      medium={runAttributes.n_defects_active_medium || 0}
      low={runAttributes.n_defects_active_low || 0}
    />
  )
}
