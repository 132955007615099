import { Button, Flex, Hide, Skeleton } from '@chakra-ui/react'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

import { memo } from 'react'

import { Link } from 'wouter'

import { useGetDefectNavigationQuery } from '../../redux/api/defects'

interface DefectNavigationProps {
  owner: string
  projectSlug: string
  targetSlug: string
  defectNumber: number
  runNumber: number | null | undefined
}

function DefectNavigationInner({ owner, projectSlug, targetSlug, runNumber, defectNumber }: DefectNavigationProps) {
  const { data: navigationData, isFetching } = useGetDefectNavigationQuery({
    owner,
    projectSlug,
    targetSlug,
    runNumber: runNumber ? runNumber : undefined,
    defectNumber
  })

  if (!navigationData) {
    return <></>
  }

  const previousLink =
    `/${owner}/${projectSlug}/${targetSlug}/-/defects/${navigationData?.previous_defect_number}` + (runNumber ? `/runs/${runNumber}` : '')

  const nextLink = `/${owner}/${projectSlug}/${targetSlug}/-/defects/${navigationData?.next_defect_number}` + (runNumber ? `/runs/${runNumber}` : '')
  return (
    <Skeleton isLoaded={!isFetching} height="5">
      <Flex flexDirection={{ base: 'column', lg: 'row' }} gap="2">
        {navigationData.previous_defect_number && (
          <Link href={previousLink}>
            <Button variant="outline" leftIcon={<ChevronLeftIcon />} iconSpacing={{ base: 0, sm: 2 }}>
              <Hide below="sm">Previous Defect</Hide>
            </Button>
          </Link>
        )}
        {navigationData.next_defect_number && (
          <Link to={nextLink}>
            <Button variant="outline" rightIcon={<ChevronRightIcon />} iconSpacing={{ base: 0, sm: 2 }}>
              <Hide below="sm">Next Defect</Hide>
            </Button>
          </Link>
        )}
      </Flex>
    </Skeleton>
  )
}

export const DefectNavigation = memo(DefectNavigationInner)
