import { ComponentStyleConfig } from '@chakra-ui/react'
import { statAnatomy } from '@chakra-ui/anatomy'

export const Stat: ComponentStyleConfig = {
  parts: statAnatomy.keys,
  baseStyle: {
    container: {
      bg: 'chakra-body-bg',
      border: '1px',
      borderRadius: 'md',
      borderColor: 'chakra-border-color'
    }
  }
}
