import { skipToken } from '@reduxjs/toolkit/query'
import { useRoute } from 'wouter'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'

import { useMemo } from 'react'

import { MayhemLogo } from '../../components/MayhemLogo'
import { useGetAccountQuery, useGetOrgMemberQuery, useWhoamiQuery } from '../../redux/api/workspace'
import { WorkspaceSidebar } from '../workspace-sidebar/WorkspaceSidebar'

import { Shell } from '../../components/Shell/Shell'

import { clearMruWorkspace } from '../workspace-dashboard/mru.slice'

import { WorkspaceDefaultRedirect } from './WorkspaceDefaultRedirect'

export const WorkspaceLayout = ({ children }: WorkspaceLayoutProps) => {
  const dispatch = useDispatch()

  const [_, workspaceParams] = useRoute('/:workspace/*?')
  const [__, projectParams] = useRoute('/:workspace/:project/*?')

  if (!workspaceParams) {
    // this should only happen if the workspace layout is rendered without matching any workspace
    throw new Error('Workspace parameter was not matched')
  }
  const currentUserSlug = useSelector((state: RootState) => state.auth.user.userSlug)
  const defaultWorkspace = useSelector((state: RootState) => state.workspace.mru) || currentUserSlug
  const workspace = workspaceParams?.workspace === '-' ? defaultWorkspace : workspaceParams?.workspace
  const project = workspaceParams?.workspace === '-' ? '-' : projectParams?.project

  const mayhemLogo = useMemo(() => MayhemLogo(), [])
  const workspaceSidebar = useMemo(() => <WorkspaceSidebar workspace={workspace} project={project} />, [workspace, project])

  const { isError } = useGetAccountQuery(workspace ? { owner: workspace } : skipToken)
  const { data: profile } = useWhoamiQuery()
  const { isError: orgMemberError } = useGetOrgMemberQuery(
    !profile
      ? skipToken
      : {
          owner: workspace,
          userSlug: profile.slug as string
        }
  )
  const { is_admin: isAdmin, slug: userSlug } = { ...profile }

  // Only admins or workspace members can view the workspace page. If viewing a project,
  // this check is skipped, since projects may be shared beyond a workspace with external
  // collaborators.
  const isWorkspaceViewBlocked = !project && !isAdmin && userSlug !== workspace && !!orgMemberError

  if (isError || isWorkspaceViewBlocked) {
    dispatch(clearMruWorkspace())
    return <WorkspaceDefaultRedirect />
  }

  return (
    <Shell sidebar={workspaceSidebar} logo={mayhemLogo}>
      {children}
    </Shell>
  )
}

export interface WorkspaceLayoutProps {
  children: JSX.Element
}
