import { Box, Card, CardBody, CardFooter, CardHeader, Flex, HStack, Heading, Show, Switch, Text } from '@chakra-ui/react'
import { useLocation } from 'wouter'

import { FilterMenuButton } from '../../components/FilterMenuButton'
import ListDefaults from '../../components/ListDefaults'
import { MayhemPagination } from '../../components/MayhemPagination'
import { useQuery } from '../../hooks'
import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { setNewPageFilters } from '../../util/location'
import { cleanInteger } from '../../util/numbers'
import {
  useGetMdsbomImageRepoPackagesQuery,
  useGetMdsbomImageRepoTagsQuery,
  useGetMdsbomReportPackagesQuery,
  useGetMdsbomWorkspaceDefectsQuery,
  useGetMdsbomWorkspaceImageReposQuery,
  useGetMdsbomReportDefectsQuery
} from '../../redux/api/mdsbom'

import { DsbomDefectRow } from './DsbomDefectRow'
import { DsbomDefectPreview } from './DsbomDefectPreview'
import { DsbomDefectModal } from './DsbomDefectModal'

interface Props {
  workspace: string
  imageRepoId?: string
  reportId?: string
}

// TODO - the API endpoint should take in string severity level so the frontend doesn't need this logic
type SeverityLevelOption = 'low' | 'medium' | 'high' | undefined
function getSeverityRange(severityLevel: SeverityLevelOption): number[] {
  switch (severityLevel) {
    case 'high':
      return [7, 10]
    case 'medium':
      return [4, 7]
    case 'low':
      return [0, 4]
    default:
      return [0, 10]
  }
}

export function DsbomDefectsList({ workspace, imageRepoId, reportId }: Props) {
  const [location, setLocation] = useLocation()
  const query = useQuery()

  const filters = {
    image: query.get('image') || undefined,
    tag: query.get('tag') || undefined,
    package: query.get('package') || undefined,
    severity: query.get('severity') || 'all',
    observed: query.get('observed') === 'true'
  }
  const [severityFilterMin, severityFilterMax] = getSeverityRange(filters.severity as SeverityLevelOption)

  const page = cleanInteger(query.get('page'), 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)
  const selectedDefectId = query.get('defect')
  const selectedDefectImageRepoId = query.get('defectImage')
  const defectSelected = selectedDefectId && selectedDefectImageRepoId

  const defectsQuery = {
    workspace,
    offset: (page - 1) * perPage,
    perPage,
    imageRepoId: imageRepoId || filters.image,
    severityGreaterThanEqual: severityFilterMin,
    severityLessThanEqual: severityFilterMax,
    observedOnly: filters.observed
  }

  const workspace_query = useGetMdsbomWorkspaceDefectsQuery({ ...defectsQuery }, { skip: !!reportId })

  const report_query = useGetMdsbomReportDefectsQuery({ ...defectsQuery, reportId: reportId ?? '' }, { skip: !reportId })

  const { data: defectsData, isFetching, isError } = reportId ? report_query : workspace_query

  const { count, defects = [] } = defectsData || {}

  // image filter options (shown for the Workspace level defects list)
  // using 100 as the perPage value in lieu of a searchable/more complex dropdown setup
  const { data: imagesData } = useGetMdsbomWorkspaceImageReposQuery({ workspace, perPage: 100 })
  const { image_repos: imagesList = [] } = imagesData || {}
  const imageOptions = imagesList.map((image) => {
    return {
      key: image.id,
      text: image.image_name,
      value: image.id
    }
  })

  const imageFilterOptionsBase = [
    {
      key: 'All Images',
      text: 'All Images',
      value: 'all'
    }
  ]
  const imageFilterOptions = imageFilterOptionsBase.concat(imageOptions)

  // tag filter options (shown for the Image level defects list)
  // using 100 as the perPage value in lieu of a searchable/more complex dropdown setup
  const { data: imageRepoTags } = useGetMdsbomImageRepoTagsQuery(
    { workspace, imageRepoId: imageRepoId || '', perPage: 100 },
    { skip: !imageRepoId || !!reportId }
  )
  const { tags = [] } = imageRepoTags || {}
  const tagOptions = tags.map((tag) => {
    return {
      key: tag,
      text: tag,
      value: tag
    }
  })

  const tagFilterOptionsBase = [
    {
      key: 'All Tags',
      text: 'All Tags',
      value: 'all'
    }
  ]
  const tagFilterOptions = tagFilterOptionsBase.concat(tagOptions)

  // package filter options (shown for the Image and Report level defects lists)
  const { data: packagesByImageData } = useGetMdsbomImageRepoPackagesQuery(
    { workspace, imageRepoId: imageRepoId || '' },
    { skip: !imageRepoId || !!reportId }
  )
  const { data: packagesByReportData } = useGetMdsbomReportPackagesQuery({ workspace, reportId: reportId || '' }, { skip: !reportId })
  const { packages: packagesByImageList = [] } = packagesByImageData || {}
  const { packages: packagesByReportList = [] } = packagesByReportData || {}
  const packagesList = [...packagesByImageList, ...packagesByReportList]

  const packageOptions = packagesList.map((pkg) => {
    return {
      key: pkg.id,
      text: pkg.name,
      value: pkg.id
    }
  })

  const packageFilterOptionsBase = [
    {
      key: 'All Packages',
      text: 'All Packages',
      value: 'all'
    }
  ]
  const packageFilterOptions = packageFilterOptionsBase.concat(packageOptions)

  // severity filter options (shown on all defects lists)
  const severityFilterOptions = [
    {
      key: 'all',
      text: 'All Severity Levels',
      value: 'all'
    },
    {
      key: 'low',
      text: 'Low Severity',
      value: 'low'
    },
    {
      key: 'medium',
      text: 'Medium Severity',
      value: 'medium'
    },
    {
      key: 'high',
      text: 'High Severity',
      value: 'high'
    }
  ]

  const selectImageFilter = (newImageFilter: unknown): void => {
    setLocation(setNewPageFilters('image', newImageFilter, filters, location), { replace: true })
  }
  const selectTagFilter = (newTagFilter: unknown): void => {
    setLocation(setNewPageFilters('tag', newTagFilter, filters, location), { replace: true })
  }
  const selectPackageFilter = (newPackageFilter: unknown): void => {
    setLocation(setNewPageFilters('package', newPackageFilter, filters, location), { replace: true })
  }
  const selectSeverityFilter = (newSeverityFilter: unknown): void => {
    setLocation(setNewPageFilters('severity', newSeverityFilter, filters, location), { replace: true })
  }
  const selectObservedFilter = (toggleEvent: React.ChangeEvent<HTMLInputElement>): void => {
    setLocation(setNewPageFilters('observed', toggleEvent.target.checked, filters, location), { replace: true })
  }

  const hidePackagesFilter = true

  return (
    <HStack align="start" gap={4}>
      <Card
        w={{ base: '100%', '2xl': defectSelected ? '50%' : '100%' }}
        minW={{ base: '100%', '2xl': defectSelected ? '50%' : '100%' }}
        maxHeight="95vh"
      >
        <CardHeader>
          <Heading variant="cardHeading">Defects</Heading>
        </CardHeader>
        <CardHeader>
          <Flex wrap="wrap" align="baseline" gap={4}>
            <Text fontSize="xs" fontWeight="semibold" color="faded">
              FILTER:
            </Text>
            {/* Only show the Image filter on the Workspace level defects list */}
            {!imageRepoId && (
              <FilterMenuButton
                defaultText="All Images"
                options={imageFilterOptions}
                onValueChange={selectImageFilter}
                value={filters.image}
                textTransform="none"
                menuItemOptions={{ textTransform: 'none' }}
              />
            )}
            {/* Only show the Tags filter on the Image level defects list */}
            {imageRepoId && !reportId && (
              <FilterMenuButton
                defaultText="All Tags"
                options={tagFilterOptions}
                onValueChange={selectTagFilter}
                value={filters.tag}
                textTransform="none"
                menuItemOptions={{ textTransform: 'none' }}
              />
            )}
            {/* Only show the Tags filter on the Image level defects list */}
            {(imageRepoId || reportId) && !hidePackagesFilter && (
              <FilterMenuButton
                defaultText="All Packages"
                options={packageFilterOptions}
                onValueChange={selectPackageFilter}
                value={filters.package}
                textTransform="none"
                menuItemOptions={{ textTransform: 'none' }}
              />
            )}
            <FilterMenuButton
              options={severityFilterOptions}
              defaultText="All Severity Levels"
              value={filters.severity}
              onValueChange={selectSeverityFilter}
            />
            <Flex align="center" gap={2}>
              <Text color="muted-accent">Observed Only</Text>
              <Switch onChange={selectObservedFilter} isChecked={filters.observed} />
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowY="auto">
          {defects.map((defect) => (
            <DsbomDefectRow key={`${defect.image_repo_id}-${defect.id}`} workspace={workspace} imageRepoId={defect.image_repo_id} defect={defect} />
          ))}
          <ListDefaults isLoading={isFetching} nItems={count} itemName="defect" isFail={isError} />
        </CardBody>
        <CardFooter justifyContent="center">
          <MayhemPagination isLoading={isFetching} total={count || 0} pageSize={perPage} />
        </CardFooter>
      </Card>

      <Show above="2xl">
        <Box width="50%">
          {defectSelected && <DsbomDefectPreview workspace={workspace} imageRepoId={selectedDefectImageRepoId} defectId={selectedDefectId} />}
        </Box>
      </Show>
      <Show below="2xl">
        {defectSelected && <DsbomDefectModal workspace={workspace} imageRepoId={selectedDefectImageRepoId} defectId={selectedDefectId} />}
      </Show>
    </HStack>
  )
}
