import { Button, ButtonProps, Divider, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'
import { Link, useLocation } from 'wouter'
import ManageSettingsIcon from '@material-design-icons/svg/sharp/manage_accounts.svg?react'
import AdminPanelSettingsIcon from '@material-design-icons/svg/sharp/admin_panel_settings.svg?react'
import LogoutIcon from '@material-design-icons/svg/sharp/logout.svg?react'
import StorageIcon from '@material-design-icons/svg/sharp/storage.svg?react'

import { memo } from 'react'

import { logout } from '../auth/actions'
import { useAppDispatch } from '../../hooks'
import { useWhoamiQuery } from '../../redux/api/workspace'

interface UserProfileButtonProps {
  containerRef: React.RefObject<HTMLDivElement>
}

const UserProfileButtonInner = (props: UserProfileButtonProps & ButtonProps) => {
  const { children, ...buttonProps } = props
  const { data: profile } = useWhoamiQuery()

  const dispatch = useAppDispatch()
  const [_location, setLocation] = useLocation()
  const onLogout = async () => {
    await dispatch(logout())
    setLocation('/-/sign-in', { replace: true })
  }

  return (
    <Menu isLazy={true}>
      <MenuButton as={Button} variant="sidenav-link" justifyContent="start" width="full" height="flex" padding={4} {...buttonProps}>
        {children}
      </MenuButton>
      <Portal containerRef={props.containerRef}>
        <MenuList>
          <MenuItem icon={<ManageSettingsIcon />} as={Link} to="/-/settings/user">
            Account Settings
          </MenuItem>
          {profile?.is_admin && (
            <MenuItem icon={<AdminPanelSettingsIcon />} as={Link} to="/-/settings/admin">
              Admin Settings
            </MenuItem>
          )}
          <Divider />
          <MenuItem icon={<StorageIcon />} as="a" href="/api/v2/api-docs/html" target="_blank" rel="noopener noreferrer">
            API Documentation
          </MenuItem>
          <Divider />
          <MenuItem icon={<LogoutIcon />} onClick={onLogout}>
            Logout
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}

export const UserProfileButton = memo(UserProfileButtonInner)
