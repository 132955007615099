import { useMemo } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Alert, Button, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import { useLocation } from 'wouter'
import { skipToken } from '@reduxjs/toolkit/query'

import RunRowParentLinks from '../runs/RunRowParentLinks'
import { CodeRunConfig } from '../run-code-config/CodeRunConfig'

import { useGetRunQuery, usePostRunMutation } from '../../redux/api/runs'
import { FormCodeRunConfig, FormCodeRunConfigContext, fromForm, toForm } from '../run-code-config/utils'
import { useCodeRunFormQueryEncoder } from '../run-code-config/hooks'
import { CoreCodeRunConfig } from '../run-code-config/CoreCodeRunConfig'
import { PartialOpaque } from '../../opaque'
import { usePostProjectMutation } from '../../redux/api/projects'
import { unwrapMutation } from '../../redux/api/utils'

import { CodeRunPreview } from './CodeRunPreview'

import { DefaultValues, DemoValues } from './defaultValues'
import { useImageConfig } from './useImageConfig'

export interface CodeRunCreateFormProps {
  workspace: string
  initialConfig: PartialOpaque<CoreCodeRunConfig>
  reference?: {
    workspace: string
    project: string
    target: string
    run: number
  }
}

export const CodeRunCreateForm = ({ workspace, initialConfig, reference }: CodeRunCreateFormProps) => {
  const [_, setLocation] = useLocation()
  const [postProject] = usePostProjectMutation()
  const postRun = usePostRunMutation()

  const { data: referenceRun } = useGetRunQuery(
    reference
      ? {
          owner: reference.workspace,
          projectSlug: reference.project,
          targetSlug: reference.target,
          runNumber: reference.run
        }
      : skipToken
  )

  // Initialize form values
  const defaultValues = useMemo(() => {
    const hasCommand = initialConfig.commands?.length && initialConfig.commands[0].command?.length
    if (!initialConfig?.project && !initialConfig?.image && !hasCommand) {
      // Form is empty, fill in the demo values
      return toForm(initialConfig, DemoValues)
    }

    return toForm(initialConfig, DefaultValues)
  }, [initialConfig])
  const context: FormCodeRunConfigContext = { workspace, testsuite: initialConfig?.testsuite || [] }

  const methods = useForm<FormCodeRunConfig>({
    defaultValues,
    // form validation does not work on default mode
    mode: 'onChange'
  })
  const {
    handleSubmit,
    getValues,
    setError,
    formState: { errors, isSubmitting, isValid }
  } = methods

  // Fill the form using the image manifest
  useImageConfig(initialConfig, methods)

  useCodeRunFormQueryEncoder(methods, context)
  const errorMessage = errors.root?.message
  const formConfig = fromForm(getValues(), context)

  const onSubmit: SubmitHandler<FormCodeRunConfig> = async (data) => {
    // Create a project with the form name first.
    const project = unwrapMutation(
      await postProject({
        owner: workspace,
        project: {
          project_name: data.project
        }
      }),
      (error) => ({ project_slug: (error.data as { project_slug: string }).project_slug })
    )

    if (project.error && !project.conflict) {
      setError('project', { message: 'failed to create project' })
      return
    }

    const { error: runError, data: runData } = {
      error: undefined,
      data: undefined,
      ...(await postRun(fromForm(data, context)))
    }
    if (runError) {
      if ('project' in runError && 'status' in runError.project) {
        setError('project', { message: (runError.project?.data as { message: string } | undefined)?.message })
      } else if ('target' in runError && 'status' in runError.target) {
        setError('target', { message: (runError.target?.data as { message: string } | undefined)?.message })
      }

      if ('mayhemfile' in runError && 'status' in runError.mayhemfile) {
        setError('root', { message: (runError.mayhemfile?.data as { message: string } | undefined)?.message })
      } else if ('run' in runError && 'status' in runError.run) {
        setError('root', { message: (runError.run?.data as { message: string } | undefined)?.message })
      }

      console.error(runError)
      // TODO(kostas): Set form errors from response
    } else if (runData) {
      const runUrl = `/${runData.owner}/${runData.project_slug as string}/${runData.target_slug as string}/${runData.run_number as number}`
      setLocation(runUrl)
    }
  }

  return (
    <Stack spacing={8}>
      <Stack spacing={2}>
        <Heading size="lg">Add new code project</Heading>
        <Text> How do you want to keep track of this project?</Text>
        {referenceRun && (
          <HStack>
            <Text>From run:</Text>
            <RunRowParentLinks
              owner={referenceRun.owner}
              projectSlug={referenceRun.project_slug as string}
              targetSlug={referenceRun.target_slug as string}
              runNumber={referenceRun.run_number as number}
              fadedText={false}
              isAccessible
            />
          </HStack>
        )}
      </Stack>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CodeRunConfig workspace={workspace} />
        </form>
      </FormProvider>

      <Stack spacing={2} px={4}>
        <HStack>
          <Button isLoading={isSubmitting} isDisabled={!isValid} onClick={handleSubmit(onSubmit)} data-selenium-id="docker-flow-start-run-button">
            Create project and run
          </Button>
        </HStack>
        {errorMessage && !isSubmitting && (
          <Alert status="error" variant="solid" whiteSpace="pre-wrap">
            {formatError(errorMessage)}
          </Alert>
        )}
      </Stack>

      <Stack spacing={2} px={4}>
        <CodeRunPreview config={formConfig} />
      </Stack>
    </Stack>
  )
}

const formatError = (message: string) => {
  // TODO(kostas): Investigate how messages are generate and improve rendering
  return message
}
