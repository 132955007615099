import { Link, useLocation } from 'wouter'

import { ChangeEvent, ReactNode } from 'react'
import { Button, HStack, Select, Text } from '@chakra-ui/react'

import { useQuery } from '../hooks'
import { setPageQueryParam, setQueryParam, stringifyUrl } from '../util/location'
import { cleanInteger } from '../util/numbers'

import { DEFAULT_PER_PAGE, DEFAULT_PER_PAGE_OPTIONS } from '../redux/api/mayhemApi'

import { Pagination } from './Pagination/Pagination'

type Props = {
  /** Set to true to display the loading state. */
  isLoading: boolean
  /**
   * The query parameter prefix.  For example, set to `trip` if the query param
   * desired is `trippage`
   */
  prefix?: string
  /** The size of a single page.  Defaults to 15 */
  pageSize?: number
  /** The options available for page size. Defaults to [15,30,45] */
  pageSizeOptions?: number[]

  /**
   * The total number of records.
   * This is used to compute the number of pages available.
   */
  total: number
}

export function MayhemPagination({ isLoading, pageSize = DEFAULT_PER_PAGE, pageSizeOptions = DEFAULT_PER_PAGE_OPTIONS, total, prefix = '' }: Props) {
  const [location, setLocation] = useLocation()

  const queryParams = useQuery()

  const page = cleanInteger(queryParams.get(`${prefix}page`), 1)

  const paginationChangeHandler = (page: number): void => {
    if (page === 1) {
      queryParams.delete(`${prefix}page`)
      const newUrl = stringifyUrl(location, Object.fromEntries(queryParams))
      setLocation(newUrl)
    } else {
      const newUrl = setPageQueryParam(location, queryParams, page, prefix)
      setLocation(newUrl)
    }
  }

  const renderPageItem = (current: number, isDisabled: boolean, isLoading: boolean): ReactNode => {
    const href = setPageQueryParam(location, queryParams, page, prefix)
    return (
      <Button as={Link} size="md" variant="outline" colorScheme="gray" isDisabled={isDisabled} isLoading={isLoading} to={href}>
        {current}
      </Button>
    )
  }

  const pageSizeChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const newUrl = setQueryParam({ location, queryParams, param: 'perPage', value: e.target.value.toString() })
    setLocation(newUrl)
  }

  // only show the page size selector if there's more than 1 of the minimum size page
  const showPageSizeSelector = total > pageSizeOptions[0]

  return (
    <HStack align="center" gap={8}>
      <Pagination
        current={page}
        total={total}
        isLoading={isLoading}
        onChange={paginationChangeHandler}
        renderPageItem={renderPageItem}
        pageSize={pageSize}
      />
      {showPageSizeSelector && (
        <HStack gap={2}>
          <Text>Results per page:</Text>
          <Select value={pageSize} onChange={pageSizeChangeHandler} width="auto">
            {pageSizeOptions.map((pageSizeOption) => (
              <option key={`page-size-option-${pageSizeOption}`} value={pageSizeOption}>
                {pageSizeOption}
              </option>
            ))}
          </Select>
        </HStack>
      )}
    </HStack>
  )
}
