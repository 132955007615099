import { useEffect } from 'react'
import { Heading, Stack, Tabs, Tab, TabList, TabPanels, TabPanel } from '@chakra-ui/react'

import { Page } from '../../components/Page'

import { getWorkspacePageTitle } from '../../titles'

import { Category } from './integration'
import { IntegrationList } from './IntegrationList'

import { MAYHEM_INTEGRATIONS } from './MayhemIntegrations'

interface Props {
  workspace: string
}

export const IntegrationsPage = ({ workspace }: Props) => {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'Integrations')
  }, [workspace])

  const languages = MAYHEM_INTEGRATIONS.filter((integration) => integration.categories.some((item) => Category.LANGUAGE === item))
  const apis = MAYHEM_INTEGRATIONS.filter((integration) => integration.categories.some((item) => Category.API === item))
  const cicd = MAYHEM_INTEGRATIONS.filter((integration) => integration.categories.some((item) => Category.CICD === item))
  const notifications = MAYHEM_INTEGRATIONS.filter((integration) => integration.categories.some((item) => Category.NOTIFICATION === item))
  const other = MAYHEM_INTEGRATIONS.filter((integration) => integration.categories.some((item) => Category.OTHER === item))
  const buildtools = MAYHEM_INTEGRATIONS.filter((integration) => integration.categories.some((item) => Category.BUILDTOOL === item))

  return (
    <Page>
      <Stack spacing={8}>
        <Heading>Integrate Mayhem</Heading>
        <Tabs variant="line">
          <TabList>
            <Tab>Languages ({languages.length}) </Tab>
            <Tab>API ({apis.length}) </Tab>
            <Tab>CI/CD ({cicd.length}) </Tab>
            <Tab>Build tools ({buildtools.length}) </Tab>
            <Tab>Notifications ({notifications.length}) </Tab>
            <Tab>Other ({other.length}) </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <IntegrationList integrations={languages} />
            </TabPanel>
            <TabPanel>
              <IntegrationList integrations={apis} />
            </TabPanel>
            <TabPanel>
              <IntegrationList integrations={cicd} />
            </TabPanel>
            <TabPanel>
              <IntegrationList integrations={buildtools} />
            </TabPanel>
            <TabPanel>
              <IntegrationList integrations={notifications} />
            </TabPanel>
            <TabPanel>
              <IntegrationList integrations={other} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Page>
  )
}
