import { HStack } from '@chakra-ui/react'

import { DefectsTag } from './DefectsTag'

export interface DefectBySeverityRowProps {
  high: number
  medium: number
  low: number
}

export const DefectsBySeverityRow = ({ high, medium, low }: DefectBySeverityRowProps) => {
  return (
    <HStack spacing={2}>
      <DefectsTag severity="High" count={high} />
      <DefectsTag severity="Medium" count={medium} />
      <DefectsTag severity="Low" count={low} />
    </HStack>
  )
}
