import { ReactNode, useCallback } from 'react'
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, SortingState, Updater, useReactTable } from '@tanstack/react-table'
import { Box, chakra, Icon, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import KeyboardArrowUp from '@material-design-icons/svg/sharp/keyboard_arrow_up.svg?react'
import KeyboardArrowDown from '@material-design-icons/svg/sharp/keyboard_arrow_down.svg?react'
import UnfoldMore from '@material-design-icons/svg/sharp/unfold_more.svg?react'

import { HoverTableRow } from '../../components/TableContainer/HoverTableRow'

export interface ReactTableAdapterProps<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[]

  items: T[]

  sorting: SortingState
  onSortingChange: (value: SortingState) => void
  onRowClick: (item: T) => void

  children?: ReactNode
}

export const ReactTableAdapter = <T,>({ columns, items, sorting, onSortingChange, onRowClick, children }: ReactTableAdapterProps<T>) => {
  const handleSortingChange = useCallback(
    (value: Updater<SortingState>) => {
      if (value instanceof Function) {
        const next = value(sorting)
        onSortingChange(next)
      } else {
        onSortingChange(value)
      }
    },
    [sorting, onSortingChange]
  )

  const table = useReactTable<T>({
    columns: columns,
    data: items,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: handleSortingChange,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    }
  })

  return (
    <Box overflowX="auto">
      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id} onClick={header.column.getToggleSortingHandler()} cursor={header.column.getCanSort() ? 'pointer' : undefined}>
                  <chakra.span display="inline-flex" alignItems="center">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    <chakra.span pl="4">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === 'desc' ? (
                          <Icon boxSize={6} as={KeyboardArrowDown} aria-label="sorted descending" />
                        ) : (
                          <Icon boxSize={6} as={KeyboardArrowUp} aria-label="sorted ascending" />
                        )
                      ) : header.column.getCanSort() ? (
                        <Icon boxSize={6} as={UnfoldMore} aria-label="unsorted" />
                      ) : null}
                    </chakra.span>
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {children}
          {!children &&
            table.getRowModel().rows.map((row) => (
              <HoverTableRow
                key={row.id}
                onClick={() => {
                  onRowClick(row.original)
                }}
                onKeyDown={(e) => {
                  if (e.key == 'Enter') {
                    // Only handle 'Enter' key if the event originator matches
                    // what we want (the row). This ensures that other links
                    // present in the row, which are different from the overall
                    // row handler, can still be invoked.
                    if (e.target === e.currentTarget) {
                      onRowClick(row.original)
                    }
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  const meta = cell.column.columnDef.meta as undefined | { isNumeric: boolean }
                  return (
                    <Td key={cell.id} isNumeric={meta?.isNumeric} borderBottomWidth={0}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  )
                })}
              </HoverTableRow>
            ))}
        </Tbody>
      </Table>
    </Box>
  )
}
