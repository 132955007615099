import { useEffect } from 'react'

import { Box, Flex, Button, Card, CardBody, CardHeader, Code, Link, Heading, Stack, StackDivider, Text } from '@chakra-ui/react'

import { ListItem, UnorderedList } from '@chakra-ui/react'

import { ApiTokenModal } from '../user/ApiTokenModal'

import { Page } from '../../components/Page'

import { getCLIInstallationPageTitle } from '../../titles'

import { getCurrentUserInfo } from '../auth/utils'
import { useAppSelector } from '../../hooks'
import { CodeBlock } from '../../components/CodeBlock/CodeBlock'

type MacOrLinux = 'linux' | 'mac'

const getUrlPathComponent = (os: MacOrLinux) => {
  switch (os) {
    case 'mac':
      return 'Darwin'
    default:
      return 'Linux'
  }
}

const unixLikeCurlCmd = (os: MacOrLinux) => {
  return `curl --fail -L ${window.location.protocol}//${window.location.host}/cli/${getUrlPathComponent(os)}/install.sh | sh`
}

const windowsCurlBat = () => {
  return `curl --fail -Lo mayhem.bat ${window.location.protocol}//${window.location.host}/cli/Windows/install.bat && mayhem.bat`
}

const windowsCurlMsi = () => {
  return `curl --fail -Lo mayhem.msi ${window.location.protocol}//${window.location.host}/cli/Windows/mayhem.msi && msiexec /i mayhem.msi`
}

interface HeadingWithNumberProps {
  number: number
  color: string
  text: string
}

function HeadingWithNumber({ number, color, text }: HeadingWithNumberProps) {
  return (
    <Heading size="xs" textTransform="uppercase" pb={1}>
      <Flex alignItems="center">
        <Box
          borderRadius="full"
          backgroundColor={color}
          width="24px"
          height="24px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginRight={2}
        >
          <Text color="white">{number}</Text>
        </Box>
        <Text>{text}</Text>
      </Flex>
    </Heading>
  )
}

export function CLIInstallPage() {
  useEffect(() => {
    document.title = getCLIInstallationPageTitle()
  })

  const { userSlug: currentUserSlug } = useAppSelector((state) => getCurrentUserInfo(state) || {})

  const mayhemLoginCmd = `mayhem login ${window.location.protocol}//${window.location.host}/ <API_TOKEN>`
  const mapiLoginCmd = `mapi --mayhem-url ${window.location.protocol}//${window.location.host} login <API_TOKEN>`

  const installCurlCmd = `# Linux CLI
${unixLikeCurlCmd('linux')}
  
# MacOS CLI
${unixLikeCurlCmd('mac')}

# Windows bat script
${windowsCurlBat()}

# Windows Installer (Requires Administrator privileges)
${windowsCurlMsi()}`

  const loginCode = `# Log in code analysis CLI
${mayhemLoginCmd}

# Log in API analysis CLI
${mapiLoginCmd}`

  return (
    <Page>
      <Stack spacing={12}>
        <Card>
          <CardHeader>
            <Heading size="md">Mayhem CLI install and quick start</Heading>
          </CardHeader>
          <CardBody p={4}>
            <Stack divider={<StackDivider />} spacing={4} p={2}>
              <Text size="sm">
                Mayhem provides you two CLIs for interacting with Mayhem from your computer and CI/CD system.
                <UnorderedList spacing={2} padding={2}>
                  <ListItem>
                    <Code>mayhem</Code> for interacting with code targets.
                  </ListItem>
                  <ListItem>
                    <Code>mapi</Code> for interacting with API targets.
                  </ListItem>
                </UnorderedList>
                Here is how to get started.
              </Text>

              <Box>
                <HeadingWithNumber number={1} color="bad" text="Install CLI" />
                <Box p={4}>
                  <Stack>
                    <Stack direction="row" pb={4}>
                      <Box>
                        <Link download href="/cli/Darwin/mayhem.pkg">
                          <Button size="sm" name="MacOS Download">
                            MacOS Download
                          </Button>
                        </Link>
                      </Box>
                      <Box pl={2}>
                        <Link download href="/cli/Windows/mayhem.msi">
                          <Button size="sm" name="Windows Download">
                            Windows Download
                          </Button>
                        </Link>
                      </Box>
                    </Stack>
                  </Stack>
                  <Stack direction="row">
                    <Box>
                      <Text>
                        or on Linux:
                        <CodeBlock>{unixLikeCurlCmd('linux')}</CodeBlock>
                      </Text>
                      <Text color="faded" size="xs" pt={2}>
                        (If you do not have internet access, add the curl <Code>--insecure</Code> option)
                      </Text>
                    </Box>
                  </Stack>
                </Box>
              </Box>

              <Box>
                <HeadingWithNumber number={2} color="bad" text="Generate API Token" />
                <Box p={4}>
                  <ApiTokenModal owner={currentUserSlug} />
                </Box>
              </Box>

              <Box>
                <HeadingWithNumber number={3} color="bad" text="Login the CLIs" />
                <Box p={4}>
                  <CodeBlock>{loginCode}</CodeBlock>
                </Box>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <Heading size="md">Install with curl</Heading>
          </CardHeader>
          <CardBody p={4}>
            <Stack>
              <Text>
                You can also install the CLIs directly with <Code>curl</Code>:
              </Text>
              <CodeBlock>{installCurlCmd}</CodeBlock>
            </Stack>
          </CardBody>
        </Card>
      </Stack>
    </Page>
  )
}
