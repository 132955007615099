import { ComponentStyleConfig } from '@chakra-ui/react'

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 600
  },
  variants: {
    cardHeading: {
      fontSize: 'lg'
    }
  }
}
