import { Stack } from '@chakra-ui/react'

import { useEffect } from 'react'

import { WorkspaceInviteSendForm } from '../invites/WorkspaceInviteSendForm'
import { WorkspaceInviteList } from '../invites/WorkspaceInviteList'

import { getWorkspacePageTitle } from '../../titles'

import { MembersCard } from './MembersCard'

export interface Props {
  allowWrite: boolean
  workspace: string
}

export function MembersTab({ allowWrite, workspace }: Props) {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspace, 'Members')
  }, [workspace])

  return (
    <Stack spacing={4}>
      {allowWrite && <WorkspaceInviteSendForm owner={workspace} />}
      {allowWrite && <WorkspaceInviteList owner={workspace} />}
      <MembersCard workspace={workspace} />
    </Stack>
  )
}
