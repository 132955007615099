/**
 * These are the main colors that make up the majority of the colors used in the design system.
 *
 * These should match what is specified in the One Mayhem figma:
 * https://www.figma.com/file/yJ6ZUUNAiGvKCLAA6cVcEG/One-Mayhem-v3.1?type=design&node-id=1023-36350
 */

/**
 * The "brand" color is the primary color, and is used across all interactive elements such as buttons, links, inputs,
 * etc. This color can define the overall feel and can elicit emotion.
 */
export const brand = {
  25: '#F5FAFF',
  50: '#F0F8FF',
  100: '#E1F2FE',
  200: '#B3DEFF',
  300: '#B3DEFF',
  400: '#53B1FD',
  500: '#2E91FA',
  600: '#1570EF',
  700: '#1766D3',
  800: '#184EAA',
  900: '#194185'
}

/**
 * Gray is a neutral color and is the foundation of the color system. Almost everything in UI design — text, form
 * fields, backgrounds, dividers — are usually gray.
 */
export const gray = {
  25: '#FCFCFD',
  50: '#F9F9FB',
  100: '#F2F2F7',
  200: '#EAEAF0',
  300: '#D0D5DD',
  400: '#98A2B3',
  500: '#667085',
  600: '#475467',
  700: '#344054',
  800: '#1D2939',
  825: '#1B2736',
  900: '#101828'
}

/**
 * Error colors are used across error states and in "destructive" actions. They communicate a destructive/negative
 * action, such as removing a user from your team.
 */
export const error = {
  25: '#FFFBFA',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#FECDCA',
  300: '#FDA29B',
  400: '#F97066',
  500: '#F04438',
  600: '#D92D20',
  700: '#B42318',
  800: '#912018',
  900: '#7A271A'
}

/**
 * Warning colors can communicate that an action is potentially destructive or "on-hold". These colors are commonly
 * used in confirmations to grab the users' attention.
 */
export const warning = {
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E'
}

/**
 * Success colors communicate a positive action, positive trend, or a successful confirmation. If you're using green
 * as your primary color, it can be helpful to introduce a different hue for your success green.
 */
export const success = {
  25: '#F6FEF9',
  50: '#ECFDF3',
  100: '#D1FADF',
  200: '#A6F4C5',
  300: '#6CE9A6',
  400: '#32D583',
  500: '#12B76A',
  600: '#039855',
  700: '#027A48',
  800: '#05603A',
  900: '#054F31'
}

export const severityMedium = {
  // Define 25 and 50 so severity-medium is part of the colorScheme theme declaration.
  25: undefined,
  50: undefined,
  100: '#FFECCC',
  200: '#FFDFAD',
  300: '#FDD291',
  400: '#FFBB63',
  500: '#FFA037',
  600: '#F68511',
  700: '#E46F00',
  800: '#CB5D00',
  900: '#B15C00'
}

export const severityLow = {
  // Define 25 and 50 so severity-medium is part of the colorScheme theme declaration.
  25: undefined,
  50: undefined,
  100: '#FBF198',
  200: '#F8E750',
  300: '#F8D904',
  400: '#E8C600',
  500: '#D7B300',
  600: '#C49F00',
  700: '#B09C00',
  800: '#9B7800',
  900: '#856600'
}
