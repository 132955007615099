import { Button, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import MoreHorizontalIcon from '@material-design-icons/svg/sharp/more_horiz.svg?react'

interface Props {
  eventAttributes: Record<string, string>
  owner: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  isButton?: boolean
}

export function EventArtifactsButton(props: Props) {
  const { eventAttributes = {}, owner, projectSlug, targetSlug, runNumber, isButton = true } = props

  return (
    <Menu>
      {isButton ? (
        <MenuButton as={IconButton} icon={<MoreHorizontalIcon />} variant="outline" aria-label="Run Event Artifacts" title="Run Event Artifacts" />
      ) : (
        <MenuButton as={Button}>View Related Artifacts</MenuButton>
      )}
      <MenuList>
        {Object.entries(eventAttributes).map(([_key, _val]) => (
          <MenuItem
            as="a"
            key={_key}
            href={`/api/v2/owner/${owner}/project/${projectSlug}/target/${targetSlug}/run/${runNumber}/artifact/${_val}`}
            download
          >
            {_key}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
