import { useEffect } from 'react'

import { getProjectBuildsPageTitle } from '../../titles'

import { ProjectBuildsList } from '../project-builds/ProjectBuildsList'

type Props = {
  owner: string
  projectSlug: string
}

export function ProjectBuildsPage(props: Props) {
  useEffect(() => {
    document.title = getProjectBuildsPageTitle(props.owner, props.projectSlug)
  }, [props])

  return <ProjectBuildsList {...props} />
}
