import { SimpleGrid } from '@chakra-ui/react'

import { Integration } from './integration'
import { IntegrationCard } from './IntegrationCard'

interface IntegrationListProps {
  integrations: Integration[]
}

export function IntegrationList({ integrations }: IntegrationListProps) {
  const items = integrations.map((integration) => <IntegrationCard key={integration.id} integration={integration}></IntegrationCard>)

  return (
    <SimpleGrid spacing={4} templateColumns="repeat(auto-fill, minmax(200px, 1fr))">
      {items}
    </SimpleGrid>
  )
}
