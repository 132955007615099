import { Flex } from '@chakra-ui/react'
import { forwardRef } from 'react'

export const Sidebar = forwardRef<HTMLDivElement, { children?: JSX.Element }>(function SidebarInner(props, ref) {
  const { children } = props

  return (
    <Flex
      overflowY="auto"
      p={4}
      top={{ base: undefined, lg: 0 }}
      gridArea="sidebar"
      borderRight="1px"
      borderColor="inherit"
      sx={{
        '@media print': {
          display: 'none'
        }
      }}
      ref={ref}
    >
      {children}
    </Flex>
  )
})
