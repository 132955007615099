import { Avatar, GridProps, Skeleton, SkeletonCircle } from '@chakra-ui/react'

import { skipToken } from '@reduxjs/toolkit/query'

import { HStack } from '@chakra-ui/layout'

import { memo } from 'react'

import { useGetAccountQuery, useWhoamiQuery } from '../../redux/api/workspace'

import { WorkspaceDropdown } from './WorkspaceDropdown'

const WorkspaceWidgetInner = ({ workspace }: WorkspaceWidgetProps) => {
  const { data: profile } = useWhoamiQuery()
  const { isLoading: isLoadingActiveWorkspace, data: activeWorkspace } = useGetAccountQuery(workspace ? { owner: workspace } : skipToken)

  if (isLoadingActiveWorkspace) {
    return (
      <HStack spacing={2}>
        <SkeletonCircle height={10} width={10} />
        <Skeleton height={5} width={36} />
      </HStack>
    )
  }

  const icon =
    profile !== undefined && workspace !== undefined && profile.slug == workspace ? (
      <Avatar name={activeWorkspace?.orgname} src={profile.avatar ?? undefined} height={10} width={10} lineHeight="2rem" bg="gray" color="white" />
    ) : (
      <Avatar name={activeWorkspace?.orgname} height={10} width={10} lineHeight="2rem" bg="gray" color="white" />
    )

  return <WorkspaceDropdown workspace={workspace} icon={icon} menuButton={{ width: 'full' }} />
}

export const WorkspaceWidget = memo(WorkspaceWidgetInner)

export interface WorkspaceWidgetProps {
  container?: GridProps
  workspace?: string
}
