import { memo, useRef } from 'react'
import { Link, useRoute } from 'wouter'
import { AnimatePresence, motion } from 'framer-motion'
import { Box, Divider, Link as ChakraLink, Spacer, Stack, Text } from '@chakra-ui/react'

import { skipToken } from '@reduxjs/toolkit/query'

import AdminPanelSettingsIcon from '@material-design-icons/svg/sharp/admin_panel_settings.svg?react'
import ArrowBackIcon from '@material-design-icons/svg/sharp/arrow_back.svg?react'
import BugReportIcon from '@material-design-icons/svg/sharp/bug_report.svg?react'
import BuildIcon from '@material-design-icons/svg/sharp/build.svg?react'
import DownloadIcon from '@material-design-icons/svg/sharp/download.svg?react'
import DynamicSBOMIcon from '@material-design-icons/svg/sharp/assignment.svg?react'
import ExtensionIcon from '@material-design-icons/svg/sharp/extension.svg?react'
import GridViewIcon from '@material-design-icons/svg/sharp/grid_view.svg?react'
import HomeIcon from '@material-design-icons/svg/sharp/home.svg?react'
import LayersIcon from '@material-design-icons/svg/sharp/layers.svg?react'
import LibraryBooksIcon from '@material-design-icons/svg/sharp/library_books.svg?react'
import LockIcon from '@material-design-icons/svg/sharp/lock.svg?react'
import MembersIcon from '@material-design-icons/svg/sharp/people.svg?react'
import SettingsIcon from '@material-design-icons/svg/sharp/settings.svg?react'
import TimerIcon from '@material-design-icons/svg/sharp/timer.svg?react'
import WebhookIcon from '@material-design-icons/svg/sharp/webhook.svg?react'
import MoneyIcon from '@material-design-icons/svg/sharp/monetization_on.svg?react'

import { useGetOrgMemberQuery, useWhoamiQuery } from '../../redux/api/workspace'
import { useGetProjectMemberPermissionQuery } from '../../redux/api/projects'

import { getCurrentUserInfo } from '../auth/utils'
import { NavButton } from '../../components/Shell/NavButton'
import { Sidebar } from '../../components/Shell/Sidebar'
import { useAppSelector } from '../../hooks'
import GitBranchIcon from '../../images/git-branch.svg?react'

import { NavbarSearch } from '../workspace/NavbarSearch'

import { AboutMayhem } from './AboutMayhem'
import { UserProfileWidget } from './UserProfileWidget'
import { WorkspaceWidget } from './WorkspaceWidget'

const mainVariants = {
  initial: {
    x: '0px'
  },
  animate: {
    x: '0px',
    transition: {
      duration: 0.1
    }
  },
  exit: {
    x: 'calc(-100%)',
    transition: {
      duration: 0.1
    },
    visible: false
  }
}
const targetVariants = {
  initial: {
    x: 'calc(100%)',
    visible: false
  },
  animate: {
    x: '0px',
    transition: {
      duration: 0.1
    }
  },
  exit: {
    x: 'calc(100%)',
    transition: {
      duration: 0.1
    },
    visible: false
  }
}

const InstallationButton = () => {
  return (
    <Link to="/-/installation">
      <NavButton fontSize="md" label="Install CLI" icon={DownloadIcon} isActive={useRoute('/-/installation')[0]} />
    </Link>
  )
}

const WorkspaceSidebarInner = ({ workspace, project }: WorkspaceSidebarProps) => {
  const isInsideProject = project && project !== '-'
  const containerRef = useRef<HTMLDivElement>(null)
  // TODO(kostas): Remove @ts-ignore when framer-motion is upgraded
  return (
    <Sidebar key="workspace-sidebar" ref={containerRef}>
      <Stack flex="1" overflowX="auto" spacing={4}>
        <WorkspaceWidget workspace={workspace} />
        <NavbarSearch />
        <Box>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <AnimatePresence mode="wait">
            {!isInsideProject ? <WorkspaceMenu workspace={workspace} /> : <ProjectMenu workspace={workspace} project={project as string} />}
          </AnimatePresence>
        </Box>
        <Spacer />
        <Stack spacing={1}>
          <AboutMayhem />
          <InstallationButton />
          <ChakraLink href="/docs/" isExternal>
            <NavButton fontSize="md" label="Documentation" icon={LibraryBooksIcon} />
          </ChakraLink>
        </Stack>
        <Divider />
        <UserProfileWidget containerRef={containerRef} />
      </Stack>
    </Sidebar>
  )
}

export const WorkspaceSidebar = memo(WorkspaceSidebarInner)

export interface WorkspaceSidebarProps {
  workspace: string
  project?: string
}

export const WorkspaceMenu = ({ workspace }: WorkspaceMenuProps) => {
  const { data: profile } = useWhoamiQuery()
  const { data: currentMember } = useGetOrgMemberQuery(
    !profile
      ? skipToken
      : {
          owner: workspace,
          userSlug: profile.slug as string
        }
  )
  const { is_admin: isAdmin, slug: userSlug } = { ...profile }
  const { owns: isOwner } = { ...currentMember }
  const allowPlanAccess = !!isOwner || !!isAdmin || userSlug === workspace

  let activeNavKey: string | undefined

  const matchWorkspaceProjects = useRoute('/:workspace/-/projects')[0]
  const matchDashAnything = useRoute('/-/*?')[0]
  const matchWorkspaceProject = useRoute('/:workspace/:project')[0]
  const matchWorkspaceProjectAnything = useRoute('/:workspace/:project/-/*?')[0]
  const matchRoot = useRoute('/')[0]
  const matchWorkspace = useRoute('/:owner')[0]
  const matchZeroStateWorkspace = useRoute('/:owner/-/getting-started')[0]
  const matchDsbom = useRoute('/:owner/-/dynamic-sbom/*?')[0]

  if (useRoute('/:workspace/-/settings/*?')[0]) {
    activeNavKey = 'workspaceSettings'
  }
  if (useRoute('/:workspace/-/settings/members')[0]) {
    activeNavKey = 'members'
  }
  if (useRoute('/:workspace/-/invites')[0]) {
    activeNavKey = 'invites'
  }
  if (useRoute('/:workspace/-/integrations')[0]) {
    activeNavKey = 'integrations'
  }
  if (useRoute('/:workspace/-/plan')[0]) {
    activeNavKey = 'plan'
  }
  if (matchWorkspaceProjects || (!matchDashAnything && (matchWorkspaceProject || matchWorkspaceProjectAnything))) {
    activeNavKey = 'projects'
  }
  if (useRoute('/:workspace/-/runs')[0]) {
    activeNavKey = 'runs'
  }
  if (matchDsbom) {
    activeNavKey = 'dsbom'
  }
  if (matchRoot || matchWorkspace || matchZeroStateWorkspace) {
    activeNavKey = 'dashboard'
  }

  return (
    <motion.div key="main" variants={mainVariants} initial="initial" animate="animate" exit="exit">
      <Stack spacing="1">
        <Link to={`/${workspace}`}>
          <NavButton label="Dashboard" icon={HomeIcon} isActive={activeNavKey === 'dashboard'} />
        </Link>
        <Link to={`/${workspace}/-/projects`}>
          <NavButton label="Projects" icon={LayersIcon} isActive={activeNavKey === 'projects'} />
        </Link>
        <Link to={`/${workspace}/-/runs`}>
          <NavButton label="Runs" icon={BuildIcon} isActive={activeNavKey === 'runs'} />
        </Link>
        <Link to={`/${workspace}/-/dynamic-sbom`}>
          <NavButton label="Dynamic SBOM" icon={DynamicSBOMIcon} isActive={activeNavKey === 'dsbom'} />
        </Link>
        <Link to={`/${workspace}/-/settings/members`}>
          <NavButton label="Members" icon={MembersIcon} isActive={activeNavKey === 'members'} />
        </Link>
        <Link to={`/${workspace}/-/settings`}>
          <NavButton label="Settings" icon={AdminPanelSettingsIcon} isActive={activeNavKey === 'workspaceSettings'} />
        </Link>
        <Link to={`/${workspace}/-/integrations`}>
          <NavButton label="Integrations" icon={ExtensionIcon} isActive={activeNavKey === 'integrations'} />
        </Link>
        {allowPlanAccess && (
          <Link to={`/${workspace}/-/plan`}>
            <NavButton label="Plan" icon={MoneyIcon} isActive={activeNavKey === 'plan'} />
          </Link>
        )}
      </Stack>
    </motion.div>
  )
}

export interface WorkspaceMenuProps {
  workspace: string
}

export const ProjectMenu = ({ workspace, project }: ProjectMenuProps) => {
  const { userSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { isLoading: projectMemberPermissionIsLoading, data: projectPermission } = useGetProjectMemberPermissionQuery(
    {
      owner: workspace,
      projectSlug: project,
      userSlug
    },
    { skip: !userSlug }
  )

  let activeNavKey: string | undefined

  const isAdmin = isMayhemAdmin || (!projectMemberPermissionIsLoading && projectPermission && projectPermission.permission === 'ADMIN')

  const matchRunSubpages = useRoute('/:workspace/:project/:target/:run/*?')[0]
  const matchTargetDefects = useRoute('/:workspace/:project/:target/-/defects/*?')[0]
  const matchBuildsSubpages = useRoute('/:workspace/:project/-/builds/*?')[0]

  if (useRoute('/:workspace/:project')[0]) {
    activeNavKey = 'overview'
  }
  if (useRoute('/:workspace/:project/-/runs')[0] || matchRunSubpages) {
    activeNavKey = 'runs'
  }
  if (useRoute('/:workspace/:project/-/defects')[0] || matchTargetDefects) {
    activeNavKey = 'defects'
  }
  if (useRoute('/:workspace/:project/-/builds')[0] || matchBuildsSubpages) {
    activeNavKey = 'builds'
  }
  if (useRoute('/:workspace/:project/-/settings/webhooks')[0]) {
    activeNavKey = 'webhooks'
  }
  if (useRoute('/:workspace/:project/-/settings/access-control')[0]) {
    activeNavKey = 'access control'
  }
  if (useRoute('/:workspace/:project/-/settings/general')[0]) {
    activeNavKey = 'settings'
  }

  return (
    <motion.div key="target" variants={targetVariants} initial="initial" animate="animate" exit="exit">
      <Stack spacing="1">
        <Link to={`/${workspace}`}>
          <NavButton label={workspace} icon={ArrowBackIcon} marginBottom={2} />
        </Link>
        <Divider />
        <Text fontSize="md" color="faded" paddingY={4}>
          {project}
        </Text>
        <Link to={`/${workspace}/${project}`}>
          <NavButton label="Overview" icon={GridViewIcon} isActive={activeNavKey === 'overview'} />
        </Link>
        <Link to={`/${workspace}/${project}/-/defects`}>
          <NavButton label="Defects" icon={BugReportIcon} isActive={activeNavKey === 'defects'} />
        </Link>
        <Link to={`/${workspace}/${project}/-/runs`}>
          <NavButton label="Runs" icon={TimerIcon} isActive={activeNavKey === 'runs'} />
        </Link>
        <Link to={`/${workspace}/${project}/-/builds`}>
          <NavButton label="Builds" icon={GitBranchIcon} isActive={activeNavKey === 'builds'} />
        </Link>

        {isAdmin && (
          <Link to={`/${workspace}/${project}/-/settings/webhooks`}>
            <NavButton label="Webhooks" icon={WebhookIcon} isActive={activeNavKey === 'webhooks'} />
          </Link>
        )}
        {isAdmin && (
          <Link to={`/${workspace}/${project}/-/settings/access-control`}>
            <NavButton label="Access Control" icon={LockIcon} isActive={activeNavKey === 'access control'} />
          </Link>
        )}
        {isAdmin && (
          <Link to={`/${workspace}/${project}/-/settings/general`}>
            <NavButton label="Settings" icon={SettingsIcon} isActive={activeNavKey === 'settings'} />
          </Link>
        )}
      </Stack>
    </motion.div>
  )
}

export interface ProjectMenuProps {
  workspace: string
  project: string
}
