import { ReactNode } from 'react'
import { Center, Td, Tr } from '@chakra-ui/react'
import { ColumnDef, SortingState } from '@tanstack/react-table'

import MayhemTableLoader from '../../components/MayhemTableLoader'

import { ReactTableAdapter } from './ReactTableAdapter'
import { ProjectListingError } from './ProjectListingError'
import { ProjectListingEmpty } from './ProjectListingEmpty'

export interface ListingTableProps<T> {
  workspace: string
  isLoading?: boolean
  error?: ReactNode
  items?: T[]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[]

  sorting: SortingState
  onSortingChange: (value: SortingState) => void
  onRowClick: (item: T) => void
}

export const ListingTable = <T,>({
  workspace,
  isLoading = false,
  error,
  items = [],
  sorting,
  onSortingChange,
  onRowClick,
  columns
}: ListingTableProps<T>) => {
  let tableBody
  if (isLoading) {
    tableBody = (
      <Tr>
        <Td colSpan={columns.length}>
          <Center padding={8} minH={0}>
            <MayhemTableLoader />
          </Center>
        </Td>
      </Tr>
    )
  } else if (error) {
    tableBody = (
      <Tr>
        <Td colSpan={columns.length}>
          <Center padding={8} minH={0}>
            <ProjectListingError message={error} />
          </Center>
        </Td>
      </Tr>
    )
  } else if (items.length === 0) {
    tableBody = (
      <Tr>
        <Td colSpan={columns.length}>
          <Center padding={8} minH={0}>
            <ProjectListingEmpty workspace={workspace} />
          </Center>
        </Td>
      </Tr>
    )
  }

  return (
    <ReactTableAdapter items={items} sorting={sorting} onSortingChange={onSortingChange} onRowClick={onRowClick} columns={columns}>
      {tableBody}
    </ReactTableAdapter>
  )
}
