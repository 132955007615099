import { Card, CardBody, CardHeader, Heading, Skeleton, Stack } from '@chakra-ui/react'

import { useGetMdsbomDerivedReportSetQuery } from '../../redux/api/mdsbom'
import { DownloadFileButton } from '../../components/DownloadFileButton'
import { constructReportFileDownloadUrl } from '../../util/mdsbom'

interface Props {
  workspace: string
  imageRepoId: string
  reportId: string
}

export function DsbomReportDownloads({ workspace, imageRepoId, reportId }: Props) {
  const { data: relatedReports, isFetching } = useGetMdsbomDerivedReportSetQuery({ workspace, reportId })

  const annotated_sbom_link = constructReportFileDownloadUrl(workspace, relatedReports?.annotated_sbom_report_id)
  const annotated_sca_link = constructReportFileDownloadUrl(workspace, relatedReports?.annotated_sca_report_id)
  const source_sbom_link = constructReportFileDownloadUrl(workspace, relatedReports?.source_sbom_report_id)
  const source_sca_link = constructReportFileDownloadUrl(workspace, relatedReports?.source_sca_report_id)

  return (
    <Skeleton isLoaded={!isFetching}>
      <Card maxWidth={64} key={`${workspace}/${imageRepoId}/${reportId}-downloads`}>
        <CardHeader>
          <Heading variant="cardHeading">Downloads</Heading>
        </CardHeader>
        <CardBody padding={4}>
          <Stack spacing={2}>
            <DownloadFileButton
              label="SBOM"
              href={annotated_sbom_link || ''}
              isDisabled={!annotated_sbom_link}
              aria-label="Download Annotated SBOM File"
            />
            <DownloadFileButton
              label="SCA"
              href={annotated_sca_link || ''}
              isDisabled={!annotated_sca_link}
              aria-label="Download Annotated SCA File"
            />
            <DownloadFileButton
              label="Source SBOM"
              variant="ghost"
              href={source_sbom_link || ''}
              isDisabled={!source_sbom_link}
              aria-label="Download Source SBOM File"
            />
            <DownloadFileButton
              label="Source SCA"
              variant="ghost"
              href={source_sca_link || ''}
              isDisabled={!source_sca_link}
              aria-label="Download Source SCA File"
            />
          </Stack>
        </CardBody>
      </Card>
    </Skeleton>
  )
}
