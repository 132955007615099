import { useEffect } from 'react'

import { Button, Menu, MenuButton, MenuItem, MenuList, Spinner, Text } from '@chakra-ui/react'
import ArrowDropDownIcon from '@material-design-icons/svg/sharp/arrow_drop_down.svg?react'

import { useGetDockerTagsQuery } from '../../redux/api/docker'

export interface DockerRepositoryTagMenuProps {
  repository: string
  selected?: string | null
  onSelect?: (value: string) => void
}

export const DockerRepositoryTagMenu = ({ repository, selected = null, onSelect }: DockerRepositoryTagMenuProps) => {
  const { isLoading, isSuccess, data } = useGetDockerTagsQuery({ repository })

  useEffect(() => {
    if (selected === null && isSuccess && data?.tags?.[0]) {
      onSelect?.(data?.tags?.[0])
    }
  }, [isSuccess, data, selected, onSelect])

  const tags = data?.tags?.slice()?.sort() || []

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Menu>
      {!selected && (
        <MenuButton>
          <Text color="faded">No tags found!</Text>
        </MenuButton>
      )}
      {selected && (
        <MenuButton as={Button} rightIcon={<ArrowDropDownIcon />} variant="ghost">
          {selected}
        </MenuButton>
      )}
      <MenuList>
        {tags.map((tag) => (
          <MenuItem key={tag} onClick={() => onSelect?.(tag)}>
            {tag}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
