import { As, Button, ButtonProps, HStack, Icon, Text, useColorMode } from '@chakra-ui/react'

export interface NavButtonProps extends ButtonProps {
  icon: As
  label: string
  isActive?: boolean
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, isActive, ...buttonProps } = props
  const { colorMode } = useColorMode()

  return (
    <Button
      variant="sidenav-link"
      justifyContent="start"
      width="full"
      aria-current={isActive ? 'page' : undefined}
      isActive={isActive}
      {...buttonProps}
    >
      <HStack spacing="3">
        <Icon
          as={icon}
          boxSize="6"
          color={isActive ? (colorMode === 'dark' ? 'gray.300' : 'gray.600') : colorMode === 'dark' ? 'gray.200' : 'gray.700'}
        />
        <Text>{label}</Text>
      </HStack>
    </Button>
  )
}
