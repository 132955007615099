import dayjs from 'dayjs'

/**
 * Check if a UTC-formatted string has expired.
 * @param utcDateString A UTC-formatted string (e.g., '2023-09-04T12:00:00Z').
 * @returns True if the date has expired, false otherwise.
 */
export function hasExpired(utcDateString: string): boolean {
  // Parse the UTC date string using Day.js
  const utcDate = dayjs.utc(utcDateString)

  // Get the current UTC date and time
  const currentUtcDate = dayjs.utc()

  // Compare the parsed date with the current date to check if it has expired
  return currentUtcDate.isAfter(utcDate)
}
