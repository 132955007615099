import { skipToken } from '@reduxjs/toolkit/query'
import { Card, CardBody, CardFooter, CardHeader, Heading, Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react'

import ListDefaults from '../../components/ListDefaults'
import { MayhemPagination } from '../../components/MayhemPagination'
import { cleanInteger } from '../../util/numbers'
import { useQuery } from '../../hooks'
import { useGetOrgMemberQuery, useGetOrgMembersQuery, useWhoamiQuery } from '../../redux/api/workspace'

import { MemberRow } from './MemberRow'

type Props = {
  workspace: string
}

export function MembersCard({ workspace }: Props) {
  const query = useQuery()
  const page = cleanInteger(query.get('page'), 1)

  const { data: profile } = useWhoamiQuery()
  const isAdmin = profile?.is_admin

  const { data: currentMember } = useGetOrgMemberQuery(
    !profile
      ? skipToken
      : {
          owner: workspace,
          userSlug: profile.slug as string
        }
  )
  const { owns: isOwner } = { ...currentMember }

  const pageSize = 10
  const {
    isFetching,
    isError,
    currentData: members
  } = useGetOrgMembersQuery({
    owner: workspace,
    offset: (page - 1) * pageSize,
    perPage: pageSize
  })

  return (
    <Card>
      <CardHeader>
        <Heading size="md">Active Members</Heading>
      </CardHeader>
      <CardBody>
        <Table>
          <Thead>
            <Tr>
              <Th textAlign="left">User</Th>
              <Th textAlign="left">Name</Th>
              <Th textAlign="left">Role</Th>
              <Th textAlign="center">{isOwner || profile?.is_admin ? 'Remove' : ''}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {members?.users?.map((member) => (
              <MemberRow key={member?.slug} workspace={workspace} member={member} allowEdit={isAdmin || isOwner} allowDelete={isAdmin || isOwner} />
            ))}
          </Tbody>
        </Table>
        <ListDefaults isLoading={isFetching} nItems={members?.count} itemName="member" isFail={isError} />
      </CardBody>
      <CardFooter justifyContent="center">
        <MayhemPagination isLoading={isFetching} total={members?.count || 0} pageSize={pageSize} />
      </CardFooter>
    </Card>
  )
}
