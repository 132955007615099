import { useEffect } from 'react'

import { Card, CardBody, Stack } from '@chakra-ui/react'

import { MayhemStats } from '../../components/MayhemStats'
import { getProjectRunsPageTitle } from '../../titles'

import { useInterval, useQuery } from '../../hooks'

import { ProjectRunsList } from '../project-runs/ProjectRunsList'

import { useGetRunStatsByProjectQuery } from '../../redux/api/projects'
import { formatNumber } from '../../util/numbers'

type Props = {
  owner: string
  projectSlug: string
}

export function ProjectRunsPage({ owner, projectSlug }: Props) {
  const targetFilter = useQuery().get('target') || undefined

  const {
    isLoading: isRunsStatsLoading,
    data: { active = 0, count = 0 } = {},
    refetch
  } = useGetRunStatsByProjectQuery({ owner, projectSlug, target: targetFilter })

  useEffect(() => {
    document.title = getProjectRunsPageTitle(owner, projectSlug)
  }, [owner, projectSlug])

  useInterval({
    action: refetch,
    deps: [refetch]
  })

  const stats = [
    { label: `Active Run${active !== 1 ? 's' : ''}`, value: formatNumber(active, { shorten: false }) },
    { label: `Total Run${count !== 1 ? 's' : ''}`, value: formatNumber(count, { shorten: false }) }
  ]

  return (
    <Stack spacing={8}>
      <Card>
        <CardBody p={4}>
          <MayhemStats padded stats={stats} isFetching={isRunsStatsLoading} />
        </CardBody>
      </Card>
      <ProjectRunsList owner={owner} projectSlug={projectSlug} />
    </Stack>
  )
}
