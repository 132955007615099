import { Link as WouterLink } from 'wouter'

import { Button, Card, CardBody, CardFooter, CardHeader, CardProps, Heading, Text } from '@chakra-ui/react'

import AddIcon from '@material-design-icons/svg/sharp/add.svg?react'

export const CreateDSBOMCard = ({ workspace, ...cardProps }: CreateCodeRunCardProps) => {
  return (
    <Card {...cardProps}>
      <CardHeader>
        <Heading variant="cardHeading">Dynamic SBOM</Heading>
      </CardHeader>
      <CardBody padding={4}>
        <Text>Runtime-aware SBOM and SCA</Text>
      </CardBody>
      <CardFooter justifyContent="end">
        <WouterLink to={`/${workspace}/-/dynamic-sbom-start`}>
          <Button leftIcon={<AddIcon />}>Get Started</Button>
        </WouterLink>
      </CardFooter>
    </Card>
  )
}

export type CreateCodeRunCardProps = CardProps & {
  workspace: string
}
